import React, {useEffect, useMemo, useState} from "react";
import {NotificationPanelProps} from "../interfaces/notification-panel.props";
import {
    Badge,
    Box,
    Card,
    CardHeader, ClickAwayListener, Divider,
    Fade,
    FormControlLabel,
    FormGroup,
    IconButton, Link, ListItemAvatar, ListItemButton, ListItemText, ListSubheader,
    Popper,
    Stack,
} from "@mui/material";
import {ChatBubbleOutline, Notifications, OpenInNew} from "@mui/icons-material";
import {ConnectSwitch} from "../../../connect-ui/components/connect-switch/connectSwitch";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {CardContent, CircularProgress, Typography} from "@mui/material";
import {NotificationTabsEnum} from "../enums/notification-tabs.enum";
import useAuth from "../../../../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {getComments, markAllAsRead, markAsRead} from "../state/notification-panel.effects";
import {AppState} from "../../../../store/interfaces/app.state";
import {CommentInterface} from "../interfaces/comment.interface";
import Avatar from "react-avatar";
import TimeAgo from "react-timeago";
import Editor from "@draft-js-plugins/editor";
import {CompositeDecorator, convertFromRaw, EditorState} from "draft-js";
import {flattenDeep} from "lodash";
import {mentionPluginInstance} from "../../mentions/mention.plugin";
import {useTheme} from "@mui/styles";
import {useNotificationPanelStyles} from "../styles/notification-panel.styles";
import moment from "moment";
import {TimeIntervalGroupsInterface} from "../interfaces/time-interval-groups.interface";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {AppDispatch} from "../../../../store/store";



const NotificationPanelComponent = (props: NotificationPanelProps) => {

    const isLoadingComments = useSelector((state: AppState) => state.notificationPanel.isLoadingComments);
    const comments = useSelector((state: AppState) => state.notificationPanel.comments);
    const unreadOnly = useSelector((state: AppState) => state.notificationPanel.unreadOnly);
    const [groupedComments, setGroupedComments] = useState<TimeIntervalGroupsInterface|null>(null);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [ activeTab, setActiveTab ] = useState(NotificationTabsEnum.Comments);
    const [unread, setUnread] = useState(0);
    const navigate =  useNavigate();
    const {user} = useAuth() as any;
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const classes = useNotificationPanelStyles(theme);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const groupItemsByTime = (comments: CommentInterface[]): TimeIntervalGroupsInterface => {

        const today = moment().startOf('day');

        const oneDay = moment.duration(1, 'day');
        const oneWeek = moment.duration(7, 'days');
        const oneMonth = moment.duration(1, 'month');

        const groups: TimeIntervalGroupsInterface = {
            Today: [],
            Yesterday: [],
            'Last 7 days': [],
            'Last month': [],
            Older: [],
        };

        comments.forEach((comment: CommentInterface) => {
            const itemDate = moment(comment.lastUpdateDateTime).startOf('day');
            const timeDiff = today.diff(itemDate);

            if (timeDiff < oneDay.asMilliseconds()) {
                groups.Today.push(comment);
            } else if (timeDiff < 2 * oneDay.asMilliseconds()) {
                groups.Yesterday.push(comment);
            } else if (timeDiff < oneWeek.asMilliseconds()) {
                groups['Last 7 days'].push(comment);
            } else if (timeDiff < oneMonth.asMilliseconds()) {
                groups['Last month'].push(comment);
            } else {
                groups.Older.push(comment);
            }
        });

        return groups;
    }


    const { MentionSuggestions, mentionPlugin } = useMemo(() => {
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPluginInstance;
        // eslint-disable-next-line no-shadow
        const mentionPlugin = mentionPluginInstance;
        return { mentionPlugin, MentionSuggestions };
    }, []);

    const getStateFromComment = (comment: string) =>{
        const blocks = convertFromRaw(JSON.parse(comment));
        const decorators: any = flattenDeep([mentionPlugin].map((plugin) => plugin.decorators));
        const decorator = new CompositeDecorator(decorators.filter((decorator: any, index: number) => index !== 1) );
        const state = {editorState: EditorState.createWithContent(blocks, decorator)};
        return state.editorState;
    }

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const handleChange = (event: React.SyntheticEvent, newValue: NotificationTabsEnum) => {
        setActiveTab(newValue);
    };

    const markAllRead = React.useCallback(() => {
        dispatch(markAllAsRead(user.cognitoSub, comments.filter(c => !c.isRead).map(c => c.id)))
    },[dispatch, user, comments]);

    const gotoComment = (trialId: number, participantCog: string, commentId: number, mark: boolean) => {
        setOpen(false);
        if (mark) {
            dispatch(markAsRead(user.cognitoSub, commentId));
        }
        navigate(`/trial/${trialId}#/patient/${participantCog}/comments`);
    }

    const handleSwitchChange = React.useCallback((event: any) => {
        if(user && user.cognitoSub){
            const unreadOnly = event.target.checked;
            localStorage.setItem("COMMENTS_UNREAD_ONLY", unreadOnly);
            dispatch(getComments(user.cognitoSub, unreadOnly));
        }
    }, [dispatch, user]);

    const handleOpenComments = () => {
        setOpen((previousOpen) => !previousOpen);
        navigate(`/notifications`);
    }
     
    useEffect(() => {
        if(user && user.cognitoSub){
            const unreadOnlyStorage = localStorage.getItem("COMMENTS_UNREAD_ONLY");
            const unreadOnly = unreadOnlyStorage === 'true';

            dispatch(getComments(user.cognitoSub, unreadOnly));
        }
    }, [dispatch, user]);

    useEffect(() => {
        const unreadComments = comments.filter(c => !c.isRead);
        setUnread(unreadComments.length);
        setGroupedComments(groupItemsByTime(comments.filter(c => unreadOnly ? !c.isRead : c)));
    },[comments, unreadOnly]);

    return (<React.Fragment>

        <IconButton id="notificationBell" aria-label="notifications" aria-describedby={id} type="button" onClick={handleClick}>
            <Badge color="error" badgeContent={unread} overlap="circular">
            <Notifications sx={{color: '#000'}} />
            </Badge>
        </IconButton>

        <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Popper id={id} open={open} anchorEl={anchorEl} placement={"bottom-end"} transition sx={{zIndex: 1101, minWidth: '32rem'}}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Card className={classes.card}>
                        <CardHeader

                            action={
                                <Stack direction={"row"} spacing={2}>
                                    
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="top"
                                            control={<ConnectSwitch color="primary" sx={{ marginLeft: '.5rem'}} onChange={handleSwitchChange} checked={unreadOnly} />}
                                            label="Only show unread"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                    <IconButton aria-label="expand" onClick={handleOpenComments}>
                                        <OpenInNew sx={{color: 'black'}}  />
                                    </IconButton>
                                </Stack>
                            }
                            title="Notifications"
                            subheader={"Comments"}

                        />
                        <Divider />
                        <TabContext value={activeTab}>
                            {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                            {/*    <TabList value={activeTab} onChange={handleChange} aria-label="basic tabs example">*/}
                            {/*        <Tab label="Comments" value={NotificationTabsEnum.Comments} />*/}
                            {/*        /!*<Tab label="Notifications" value={NotificationTabsEnum.Notifications} />*!/*/}
                            {/*    </TabList>*/}
                            {/*</Box>*/}
                            <CardContent className={classes.cardContent}>

                                { unread > 0 &&  <Link className={classes.markAllLink} onClick={() => markAllRead()}>Mark all as read</Link> }

                                <TabPanel value={NotificationTabsEnum.Comments} className={classes.listContainer}>

                                    {(isLoadingComments) && (
                                        <Stack>
                                            <Box mt={6} mb={2} sx={{textAlign:'center'}}>
                                                <CircularProgress />
                                            </Box>
                                        </Stack>
                                    )}

                                    { comments.length === 0 && !isLoadingComments && <Stack  direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2} sx={{padding: '2rem'}}>
                                        <ChatBubbleOutline fontSize={"large"} color={"primary"} />
                                        <Typography variant='h4' align='center'>
                                            There are { unreadOnly ? `no unread` : `no` } comments<br />
                                            <Typography variant='subtitle' align='center'>
                                                Comments you are tagged in will appear here.
                                            </Typography>
                                        </Typography>

                                    </Stack> }

                                    { !isLoadingComments && groupedComments && Object.keys(groupedComments).map((groupName: string, gi: number) => {
                                        const comments: CommentInterface[] = groupedComments[groupName];
                                        return comments.map((c: CommentInterface, i: number) => <React.Fragment key={i}>

                                            { i === 0 && <ListSubheader className={classes.listSubHeader}>{ groupName }</ListSubheader> }

                                            <ListItemButton  alignItems="flex-start" className={classes.commentContainer} onClick={() => gotoComment(c.trialId, c.patientCognitoSub, c.id, !c.isRead)}>
                                                <ListItemAvatar>
                                                    <Avatar name={`${c.authorFirstName} ${c.authorLastName}`}
                                                            size={'35'} textSizeRatio={2.5} round=".2rem"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    id={`comment-${i}`}
                                                    disableTypography={true}
                                                    primary={
                                                        <Stack direction={"row"} justifyContent={"space-between"}
                                                               alignItems={"center"} spacing={2}>
                                                            <Stack direction={"row"} justifyContent={"flex-start"}
                                                                   alignItems={"center"} spacing={2}>
                                                                <Typography
                                                                    variant="h4">{`${c.authorFirstName} ${c.authorLastName}`}</Typography>
                                                                <Typography variant="body3"><TimeAgo
                                                                    date={moment.utc(c.lastUpdateDateTime).local().format()}/></Typography>
                                                            </Stack>
                                                            <Badge color="error" variant="dot" invisible={c.isRead}/>
                                                        </Stack>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                variant="subtitle2">{c.authorCompanyName ? c.authorCompanyName : <>&mdash;</>}</Typography>
                                                            <Editor editorState={getStateFromComment(c.description)}
                                                                    plugins={[mentionPlugin]}
                                                                    onChange={() => {
                                                                    }}
                                                                    readOnly={true}/>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ padding: '1rem 1rem 0 0'}}>
                                                                <Stack direction={"row"} alignItems={"center"}>
                                                                    <Avatar value={c.trialIdentifier} size={"20"} round={false} textSizeRatio={2.5} /> <Box sx={{marginLeft: '.5rem'}} className={classNames('trialName')}>{c.trialName}</Box>
                                                                </Stack>
                                                                <Box>/</Box>
                                                                <Stack direction={"row"} alignItems={"center"}>
                                                                    <Avatar name={`${c.patientFirstName} ${c.patientLastName}`} size={"20"} round={true} textSizeRatio={2.5} /> <Box sx={{marginLeft: '.5rem'}} className={classNames('participantName')}>{`${c.patientFirstName} ${c.patientLastName}`}</Box>
                                                                </Stack>
                                                            </Stack>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItemButton>
                                            {/*<Divider variant="inset" component="li" />*/}
                                        </React.Fragment>);
                                    }
                                    )}
                                </TabPanel>
                                {/*<TabPanel value={NotificationTabsEnum.Notifications}>*/}
                                {/*    notifications*/}
                                {/*</TabPanel>*/}
                            </CardContent>
                        </TabContext>
                    </Card>
                </Fade>
            )}
        </Popper>
        </ClickAwayListener>
    </React.Fragment>);
}

export default NotificationPanelComponent;