/**
 * Enum for task status.
 * @readonly
 * @enum {{name: string}}
 */
export const TaskStatusEnum = Object.freeze({
    NOT_STARTED: 0,
    AWAITING_ACTION: 1,
    PASSED: 2,
    FAILED: 3,
    SKIPPED: 4,
    DISQUALIFIED: 5,
    ATTEMPTED: 6,
    DONE: 7,
    WITHDRAWN: 8,
    PENDING: 9,
    REFERRED: 10,
    RANDOMISED: 11,
    NOTE_GIVEN: 12,
    PENDING_REVIEW: 13,
    IN_PROGRESS: 14,
    RESCHEDULED: 15,
    REWIND: 16
});



