export const PARTICIPANT_MANAGEMENT_LOAD = '@participant-management/PARTICIPANT_MANAGEMENT_LOAD';
export const PARTICIPANT_MANAGEMENT_LOADED = '@participant-management/PARTICIPANT_MANAGEMENT_LOADED';

export const PARTICIPANT_MANAGEMENT_SELECT_USER = '@participant-management/PARTICIPANT_MANAGEMENT_SELECT_USER';
export const PARTICIPANT_MANAGEMENT_SELECTED_USER = '@participant-management/PARTICIPANT_MANAGEMENT_SELECTED_USER';
export const PARTICIPANT_MANAGEMENT_UNSELECT_USER = '@participant-management/PARTICIPANT_MANAGEMENT_UNSELECT_USER';

export const PARTICIPANT_MANAGEMENT_VERIFY = '@participant-management/PARTICIPANT_MANAGEMENT_VERIFY';
export const PARTICIPANT_MANAGEMENT_VERIFIED = '@participant-management/PARTICIPANT_MANAGEMENT_VERIFIED';
export const PARTICIPANT_MANAGEMENT_VERIFY_ERROR = '@participant-management/PARTICIPANT_MANAGEMENT_VERIFY_ERROR';

export const PARTICIPANT_MANAGEMENT_RESET_PASSWORD = '@participant-management/PARTICIPANT_MANAGEMENT_RESET_PASSWORD';
export const PARTICIPANT_MANAGEMENT_PASSWORD_RESET = '@participant-management/PARTICIPANT_MANAGEMENT_PASSWORD_RESET';
export const PARTICIPANT_MANAGEMENT_PASSWORD_RESET_ERROR = '@participant-management/PARTICIPANT_MANAGEMENT_PASSWORD_RESET_ERROR';
export const PARTICIPANT_MANAGEMENT_REMOVE_PASSWORD = '@participant-management/PARTICIPANT_MANAGEMENT_REMOVE_PASSWORD';

export const PARTICIPANT_MANAGEMENT_WITHDRAW = '@participant-management/PARTICIPANT_MANAGEMENT_WITHDRAW';
export const PARTICIPANT_MANAGEMENT_WITHDRAWN = '@participant-management/PARTICIPANT_MANAGEMENT_WITHDRAWN';