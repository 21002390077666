import {Box} from "@mui/material";
import largeLogo from "../../../assets/images/evrlink-logo-white.png";
import smallLogo from "../../../assets/images/evrlink-logo-small-white.png";
import React from "react";
import {LogoProps} from "./logo.props";
import {SizeEnum} from "../../enums/size.enum";

const LogoComponent = (props: LogoProps) => {

    return (

        <Box sx={{display: "flex", justifyContent: 'center', alignItems: 'centre', height: '2.2rem', width: '10.5rem'}} >

            <img src={props.size === SizeEnum.SMALL ? smallLogo : largeLogo} alt="Evrilink" />

        </Box>


    );
};

export default LogoComponent;