import { useEffect } from 'react';
import {useLocation} from "react-router-dom";


//-----------------------|| NAVIGATION SCROLL TO TOP ||-----------------------//

const NavigationScroll = (props) => {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [location]);

    return props.children || null;
};

export default NavigationScroll;
