export const PARTICIPANT_DASHBOARD_LOAD = '@participantDashboard/PARTICIPANT_DASHBOARD_LOAD';
export const PARTICIPANT_DASHBOARD_LOADED = '@participantDashboard/PARTICIPANT_DASHBOARD_LOADED';
export const PARTICIPANT_DASHBOARD_TOGGLE_PROGRESS_STAGE = '@participantDashboard/PARTICIPANT_DASHBOARD_TOGGLE_PROGRESS_STAGE';
export const PARTICIPANT_JOTFORM_RESULT_CHECKING = '@participantDashboard/PARTICIPANT_JOTFORM_RESULT_CHECKING';
export const PARTICIPANT_JOTFORM_RESULT = '@participantDashboard/PARTICIPANT_JOTFORM_RESULT';
export const PARTICIPANT_TRIAL_LIST_LOAD = '@participantDashboard/PARTICIPANT_TRIAL_LIST_LOAD';
export const PARTICIPANT_TRIAL_LIST_LOADED = '@participantDashboard/PARTICIPANT_TRIAL_LIST_LOADED';
export const PARTICIPANT_ADD_TO_TRIAL = '@participantDashboard/PARTICIPANT_ADD_TO_TRIAL';
export const PARTICIPANT_ADDED_TO_TRIAL = '@participantDashboard/PARTICIPANT_ADDED_TO_TRIAL';
export const PARTICIPANT_REDIRECT_TO_DASHBOARD = '@participantDashboard/PARTICIPANT_REDIRECT_TO_DASHBOARD';
export const PARTICIPANT_REDIRECT_TO_FIND_A_TRIAL = '@participantDashboard/PARTICIPANT_REDIRECT_TO_FIND_A_TRIAL';