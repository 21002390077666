import produce from 'immer';
import {
  DELETE_ACCOUNT_DELETE,
  DELETE_ACCOUNT_DELETED,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_LOAD,
  DELETE_ACCOUNT_LOADED,
} from './delete-account.actions';

const initialState = {
  isLoading: true,
  isDeletable: false,
  isDeleting: false,
  isDeleted: false,
  error: '',
};

const deleteAccountReducer = (
  state = initialState,
  action: { type: any; isDeletable?: boolean, error?: string }
) => {
  switch (action.type) {
    case DELETE_ACCOUNT_LOAD:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case DELETE_ACCOUNT_LOADED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.isDeletable = action?.isDeletable || false;
      });
    case DELETE_ACCOUNT_DELETE:
      return produce(state, (draft) => {
        draft.isDeleting = true;
      });
    case DELETE_ACCOUNT_DELETED:
      return produce(state, (draft) => {
        draft.isDeleting = false;
        draft.isDeleted = true;
      });
      case DELETE_ACCOUNT_ERROR:
        return produce(state, (draft) => {
          draft.isDeleting = false;
          draft.error = action.error || '';
        });      
    default:
      return state;
  }
};

export default deleteAccountReducer;
