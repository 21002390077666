import React, {createContext} from 'react';
import useAuth from '../hooks/useAuth';
import {UserPermissionsEnum} from "../shared/permissions/enums/user-permissions.enum";
import {RoleEnum} from "../shared/enums/role.enum";
import {PermissionStatuses, UserPermission, UserPermissions, UserPermissionTypes} from "../api/connect";
import {UserPermissionTypesEnum} from "../shared/permissions/enums/user-permission-types.enum";
import {LocationModel} from "../shared/components/location-selectors/models/location.model";
import {uniqBy} from "lodash";
import {permissionsToRole} from "../shared/permissions/functions/permission-template.function";
import {UserRoleEnum} from "../shared/enums/user-role.enum";


// Create the context
const PermissionsContext = createContext({
    hasPermissions: (resourceType: UserPermissionTypesEnum|undefined, permissions: UserPermissionsEnum[], resourceId?: number|undefined, parentResourceType?: UserPermissionTypesEnum|undefined, parentResourceId?: number|undefined): boolean => false, // default behaviour is deny
    hasParentRole: (roles: RoleEnum[]): boolean => false,
    getLocationsByTrial: (trialId: number): LocationModel[] => [],
    hasRole: (role: UserRoleEnum, resourceType: UserPermissionTypesEnum, resourceId?: number|undefined): boolean => false,
    getPendingPermissions: (resourceType: UserPermissionTypesEnum|undefined): UserPermission[] => []
});

export const PermissionProvider = ({children}:any) => {

    const {user} = useAuth() as any;
    const pendingPermissions = user?.permissions ? user?.permissions.filter((p: UserPermission) => p.status === PermissionStatuses._0) : []; //Pending permissions only
    const approvedPermissions = user?.permissions? user?.permissions.filter((p: UserPermission) => p.status === PermissionStatuses._1) : []; //Approved permissions only

    const hasPermissions = (resourceType: UserPermissionTypesEnum|undefined, permissions: UserPermissionsEnum[] = [], resourceId?: number|undefined, parentResourceType?: UserPermissionTypesEnum|undefined, parentResourceId?: number|undefined) => {
        let allowed = false;
        const hasResourceId = Boolean(resourceId);
        const hasParentResourceId = Boolean(parentResourceId);
        const locations = resourceType === UserPermissionTypesEnum.Trial && resourceId !== undefined ? getLocationsByTrial(resourceId) : [];
        const locationsIds = locations.map(l => l.id);

        if (hasParentResourceId && !hasResourceId){
            console.error('Resource id is missing. You cannot have a parent resource id without a resource id.');
            return false;
        }

        if (hasParentResourceId && !parentResourceType){
            console.error('Parent type is missing. You cannot have a parent resource id without a parent type.');
            return false;
        }

        if (user !== null) {
            if (user.role === RoleEnum.ADMIN){
                allowed = true; //override permissions check
            } else {

                // get the permissions we want based on resources
                const filteredPermissions = (hasResourceId && locationsIds.length)
                    ? approvedPermissions.filter((p: UserPermission) => hasParentResourceId
                        ? p.type === resourceType && p.resourceId === Number(resourceId) && p.parentType === parentResourceType && p.parentResourceId === Number(parentResourceId)
                        : p.type === resourceType && p.resourceId === Number(resourceId))
                    : hasResourceId ? approvedPermissions.filter((p: UserPermission) => p.type === resourceType && p.resourceId === Number(resourceId)) : approvedPermissions.filter((p: UserPermission) => p.type === resourceType);

                // check if this user has permissions for the resources
                for (let i = 0; i < permissions.length; i++) {
                    const userPermissions: UserPermissions[] = filteredPermissions.flatMap((p: UserPermission) => p.permission);
                    allowed = userPermissions.includes(permissions[i] as unknown as UserPermissions);
                    if (allowed) break;
                }

                // if no permissions found, lets check if the user is a company admin and allow them to see company owned trials but not participant related features
                if (!allowed && hasRole(UserRoleEnum.COMPANY_ADMIN, UserPermissionTypesEnum.Company) && resourceType === UserPermissionTypesEnum.Trial){
                    if (permissions.includes(UserPermissionsEnum.ReadParticipants)) {
                        allowed = false; //don't allow company admins to automatically read participant details
                    } else {
                        const allowedTrials = user.company.trials.map((t: any) => Number(t.id));
                        allowed = (hasResourceId) ? allowedTrials.includes(Number(resourceId)) : !!allowedTrials.length;
                    }
                }
            }
        }

        return allowed;
    }

    const hasParentRole = (roles: RoleEnum[] = []) => {
       let allowed = false;
       
       if (user !== null) {
           //if ([RoleEnum.ADMIN, RoleEnum.PARTICIPANT].includes(user.role)) { // temporary while we transition to new roles & permissions
               for (let i = 0; i < roles.length; i++) {
                   allowed = parseInt(user.role) === roles[i];
                   if (allowed) break;
               }
           //}
       }
       return allowed;
    }

    const getLocationsByTrial = (trialId: number): LocationModel[] => {
        const trialPermissions = approvedPermissions.filter((p: UserPermission) => p.type === UserPermissionTypesEnum.Trial as unknown as UserPermissionTypes && p.resourceId === Number(trialId));
        const locationPermissions = trialPermissions.filter((p: UserPermission) => p.parentResourceId as number > 0 && p.parentType === UserPermissionTypesEnum.Location as unknown as UserPermissionTypes)
        const locations = locationPermissions.map((p: UserPermission) => {
            const location = new LocationModel();
            location.id = p.parentResourceId;
            location.name = p.parentResourceName as string;
            return location;
        });
        return uniqBy(locations,'id') as unknown as LocationModel[];
    }

    const hasRole = (role: UserRoleEnum, resourceType: UserPermissionTypesEnum, resourceId?: number|undefined) => {
        let has = false;
        if (user !== null) {
            if (user.role === RoleEnum.ADMIN){
                has = true
            } else {
                has = permissionsToRole(approvedPermissions
                    .filter((p: UserPermission) => Number(p.type) === Number(resourceType) && (resourceId === undefined || p.resourceId === Number(resourceId)))
                    .flatMap((p: UserPermission) => p.permission) as unknown as UserPermissionsEnum[], resourceType) === role;
            }
        }
        return has;
    }

    const getPendingPermissions = (resourceType: UserPermissionTypesEnum|undefined): UserPermission[] => {
        return pendingPermissions.filter((p: UserPermission) => p.type === resourceType);
    }

    return <PermissionsContext.Provider value={{hasPermissions, hasParentRole, getLocationsByTrial, hasRole, getPendingPermissions}}>{children}</PermissionsContext.Provider>;

}

export default PermissionsContext;