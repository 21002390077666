import {FileModel} from "./file.model";


export class FileLibraryListModel extends FileModel  {

    constructor(public id: number,
                public imageKey: string,
                public description: string,
                public uploadDate: string,
                public statuses: any[],
                public participantFirstName: string,
                public participantLastName: string,
                public requestId: number,
                public patientCognito: string) {
        super(id, imageKey, description, uploadDate,requestId, patientCognito, statuses);
    }
}