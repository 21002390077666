import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, LinearProgress, StyledEngineProvider } from "@mui/material";
import EvrilinkRoutes from './routes';
import theme from './themes';
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import IdleTimer from './layout/IdleTimer';
import {AuthProvider} from './contexts/AuthContext';
import {PermissionProvider} from './contexts/permissions.context';
import {useLocation} from "react-router";
import config from "./config";
import {ConfirmProvider} from "material-ui-confirm";

import useAuth from './hooks/useAuth';
import { checkLogin } from './views/authentication/amplify/Amplify';
import AgreementsDialog from "./shared/components/agreements/agreements.dialog";
import {AgreementsProvider} from "./contexts/agreements.context";


//-----------------------|| APP ||-----------------------//

const App = () => {

    const { loginToken } = useAuth();
    let location = useLocation();
    const customization = useSelector((state) => state.customization);

    const [ wait, setWait ] = useState(false);

    ReactGA.initialize(config.ga.id);
    useEffect(() => {      
      ReactGA.send({hitType: "pageview", page: location.pathname});
      if (document.URL.includes('/?code=')){
        const googleLogin = async () => {
          await checkLogin();
          setWait(false);
        };
        setWait(true);
        void googleLogin();
      }
    }, [location, loginToken, setWait]);

    if (wait){
      return <LinearProgress />;
    }
    

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <ConfirmProvider
                    defaultOptions={{
                        titleProps: {
                            sx: {
                                fontSize: '1.3rem'
                            }
                        },
                        confirmationButtonProps: {
                            variant: "contained",
                            sx: { marginRight: '1rem'}
                        },
                    }}>
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <PermissionProvider>
                                    <AgreementsProvider>
                                        <AgreementsDialog />
                                        <EvrilinkRoutes />
                                        <IdleTimer/>
                                    </AgreementsProvider>
                            </PermissionProvider>
                        </AuthProvider>

                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
                </ConfirmProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
      
    
};

export default App;
