export const PARTICIPANT_DETAILS_OPEN = '@participantDetails/PARTICIPANT_DETAILS_OPEN';
export const PARTICIPANT_DETAILS_CLOSE = '@participantDetails/PARTICIPANT_DETAILS_CLOSE';
export const PARTICIPANT_DETAILS_TASK_STATUS_CHANGE = '@participantDetails/PARTICIPANT_DETAILS_TASK_STATUS_CHANGE';
export const PARTICIPANT_DETAILS_TOGGLE_PROGRESS_STAGE = '@participantDetails/PARTICIPANT_DETAILS_TOGGLE_PROGRESS_STAGE';
export const PARTICIPANT_DETAILS_LOAD = '@participantDetails/PARTICIPANT_DETAILS_LOAD';
export const PARTICIPANT_DETAILS_LOADED = '@participantDetails/PARTICIPANT_DETAILS_LOADED';
export const PARTICIPANT_DETAILS_RELOADED = '@participantDetails/PARTICIPANT_DETAILS_RELOADED';
export const PARTICIPANT_DETAILS_LOAD_ERROR = '@participantDetails/PARTICIPANT_DETAILS_LOAD_ERROR';
export const PARTICIPANT_DETAILS_RELOAD_ACTIVITY = '@participantDetails/PARTICIPANT_DETAILS_RELOAD_ACTIVITY';
export const PARTICIPANT_DETAILS_HTML_EMAIL_LOAD = '@participantDetails/PARTICIPANT_DETAILS_HTML_EMAIL_LOAD';
export const PARTICIPANT_DETAILS_HTML_EMAIL_LOADED = '@participantDetails/PARTICIPANT_DETAILS_HTML_EMAIL_LOADED';
export const PARTICIPANT_DETAILS_SURVEY_LOAD = '@participantDetails/PARTICIPANT_DETAILS_SURVEY_LOAD';
export const PARTICIPANT_DETAILS_SURVEY_LOADED = '@participantDetails/PARTICIPANT_DETAILS_SURVEY_LOADED';
export const PARTICIPANT_FILES_LOAD = '@participantDetails/PARTICIPANT_FILES_LOAD';
export const PARTICIPANT_FILES_LOADED = '@participantDetails/PARTICIPANT_FILES_LOADED';
export const PARTICIPANT_FILES_UPLOAD = '@participantDetails/PARTICIPANT_FILES_UPLOAD';
export const PARTICIPANT_FILES_UPLOADED = '@participantDetails/PARTICIPANT_FILES_UPLOADED';
export const PARTICIPANT_FILES_UPLOAD_FAILED = '@participantDetails/PARTICIPANT_FILES_UPLOAD_FAILED';
export const PARTICIPANT_FILES_UPLOAD_DIALOG_CLOSED = '@participantDetails/PARTICIPANT_FILES_UPLOAD_DIALOG_CLOSED';
export const PARTICIPANT_FILES_DELETE = '@participantDetails/PARTICIPANT_FILES_DELETE';
export const PARTICIPANT_FILES_DELETED = '@participantDetails/PARTICIPANT_FILES_DELETED';

export const PARTICIPANT_FILE_REQUESTS_LOAD = '@participantDetails/PARTICIPANT_FILE_REQUESTS_LOAD';
export const PARTICIPANT_FILE_REQUESTS_LOADED = '@participantDetails/PARTICIPANT_FILE_REQUESTS_LOADED';
export const PARTICIPANT_FILE_REQUESTS_UNLOAD = '@participantDetails/PARTICIPANT_FILE_REQUESTS_UNLOAD';
export const PARTICIPANT_FILE_REQUESTS_REQUEST_LOAD = '@participantDetails/PARTICIPANT_FILE_REQUESTS_REQUEST_LOAD';
export const PARTICIPANT_FILE_REQUESTS_REQUEST_LOADED = '@participantDetails/PARTICIPANT_FILE_REQUESTS_REQUEST_LOADED';
export const PARTICIPANT_FILE_REQUESTS_REQUEST_UNLOAD = '@participantDetails/PARTICIPANT_FILE_REQUESTS_REQUEST_UNLOAD';
export const PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVE = '@participantDetails/PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVE';
export const PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVED = '@participantDetails/PARTICIPANT_FILE_REQUESTS_FILE_STATUS_SAVED';
export const PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVE = '@participantDetails/PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVE';
export const PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVED = '@participantDetails/PARTICIPANT_FILE_REQUESTS_REQUEST_STATUS_SAVED';

export const PARTICIPANT_COMMENTS_LOAD = '@participantDetails/PARTICIPANT_COMMENTS_LOAD';
export const PARTICIPANT_COMMENTS_LOADED = '@participantDetails/PARTICIPANT_COMMENTS_LOADED';
export const PARTICIPANT_COMMENT_SAVE = '@participantDetails/PARTICIPANT_COMMENT_SAVE';
export const PARTICIPANT_COMMENT_SAVED = '@participantDetails/PARTICIPANT_COMMENT_SAVED';
export const PARTICIPANT_COMMENT_DELETE = '@participantDetails/PARTICIPANT_COMMENT_DELETE';
export const PARTICIPANT_COMMENT_DELETED = '@participantDetails/PARTICIPANT_COMMENT_DELETED';