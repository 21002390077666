import { useContext } from 'react';

// auth provider
 import AuthContext from '../contexts/AuthContext';

//-----------------------|| AUTH HOOKS ||-----------------------//

const useAuth = () => useContext(AuthContext);

export default useAuth;
