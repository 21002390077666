/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlacesService {

    /**
     * @param searchTerm 
     * @param countryCode 
     * @param regions 
     * @param callingUserCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiPlacesGetPredictions(
searchTerm?: string,
countryCode?: string,
regions?: boolean,
callingUserCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Places/GetPredictions',
            query: {
                'searchTerm': searchTerm,
                'countryCode': countryCode,
                'regions': regions,
                'callingUserCognitoSub': callingUserCognitoSub,
            },
        });
    }

    /**
     * @param placeId 
     * @param callingUserCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiPlacesGetPlaceDetails(
placeId?: string,
callingUserCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Places/GetPlaceDetails',
            query: {
                'placeId': placeId,
                'callingUserCognitoSub': callingUserCognitoSub,
            },
        });
    }

}
