import {RequestModel} from "../../../file-library/models/request.model";
import {FileModel} from "../../../file-library/models/file.model";

export class TrialRequestsListModel extends RequestModel {
  constructor(
    public id: number,
    public taskId: number,
    public description: string,
    public authorCognitoSub: string,
    public authorFirstName: string,
    public authorLastName: string,
    public sampleKey: string,
    public sampleImage: string,
    public instructions: string,
    public participantCognito: string,
    public participantFirstName: string,
    public participantLastName: string,
    public statuses: any[],
    public files:  FileModel[]
  ) {
    super(id, description, participantCognito, participantFirstName, participantLastName, statuses, files);
  }
}
