export const USER_MANAGEMENT_LOAD = '@user-management/USER_MANAGEMENT_LOAD';
export const USER_MANAGEMENT_LOADED = '@user-management/USER_MANAGEMENT_LOADED';
export const USER_MANAGEMENT_SELECT_USER = '@user-management/USER_MANAGEMENT_SELECT_USER';
export const USER_MANAGEMENT_SELECTED_USER = '@user-management/USER_MANAGEMENT_SELECTED_USER';
export const USER_MANAGEMENT_UNSELECT_USER = '@user-management/USER_MANAGEMENT_UNSELECT_USER';
export const USER_MANAGEMENT_TRIALS_LOAD = '@user-management/USER_MANAGEMENT_TRIALS_LOAD';
export const USER_MANAGEMENT_TRIALS_LOADED = '@user-management/USER_MANAGEMENT_TRIALS_LOADED';
export const USER_MANAGEMENT_ROLE_CHANGE = '@user-management/USER_MANAGEMENT_ROLE_CHANGE';
export const USER_MANAGEMENT_ROLE_CHANGED = '@user-management/USER_MANAGEMENT_ROLE_CHANGED';
export const USER_MANAGEMENT_TRIAL_REMOVE = '@user-management/USER_MANAGEMENT_TRIAL_REMOVE';
export const USER_MANAGEMENT_TRIAL_REMOVED = '@user-management/USER_MANAGEMENT_TRIAL_REMOVED';
export const USER_MANAGEMENT_LOCATION_CHANGE = '@user-management/USER_MANAGEMENT_LOCATION_CHANGE';
export const USER_MANAGEMENT_LOCATION_CHANGED = '@user-management/USER_MANAGEMENT_LOCATION_CHANGED';
export const USER_MANAGEMENT_SAVE_INVITED_USERS = '@user-management/USER_MANAGEMENT_SAVE_INVITED_USERS';
export const USER_MANAGEMENT_INVITED_USERS_SAVED = '@user-management/USER_MANAGEMENT_INVITED_USERS_SAVED';