import {FileStatusEnum} from "../../../shared/enums/file-status.enum";
import {FileRejectionReasonEnum} from "../../../shared/enums/file-rejection-reason.enum";

export class FileModel {
    public filename = '';
    public fileType = '';
    public status = FileStatusEnum.AwaitingAssessment;
    public rejectionReason: FileRejectionReasonEnum| null = null;
    public otherRejectionReason = '';
    public isImage = false;
    public canOpen = false;
    public isDeleting = false;

    constructor(public id: number,
                public imageKey: string,
                public description: string,
                public uploadDate: string,
                public requestId: number,
                public patientCognito: string,
                public statuses: any[]) {
        this.setFileNameAndType(imageKey);
        this.setStatus(statuses);
    }

    setFileNameAndType = (imageKey: string) => {
        const filenameBits = imageKey.split('/');
        const filename = filenameBits.pop();
        const extBits = filename?.split('.') || [];
        this.fileType = extBits.pop() || '';
        this.filename = filename || '';
        this.isImage = ['jpg','png','jpeg','gif'].includes(this.fileType);
        this.canOpen = ['jpg','png','jpeg','gif','pdf'].includes(this.fileType);
    }

    setStatus = (statuses: any[]) => {
        if (statuses.length) {

            const lastStatus = statuses.pop();
            this.status = lastStatus.imageStatus;

            if (lastStatus.imageStatus === FileStatusEnum.Rejected) {
                this.rejectionReason = lastStatus.rejectReason;
                if (lastStatus.rejectReason === FileRejectionReasonEnum.Other) {
                    this.otherRejectionReason = lastStatus.rejectionReason;
                }
            }

            if (lastStatus.imageStatus === FileStatusEnum.ReRequest) {
                this.otherRejectionReason = lastStatus.rejectionReason;
            }
        }
    }
}