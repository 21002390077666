import {useContext} from 'react';
import PermissionsContext from "../contexts/permissions.context";
import {UserPermissionsEnum} from "../shared/permissions/enums/user-permissions.enum";
import {UserPermissionTypesEnum} from "../shared/permissions/enums/user-permission-types.enum";

const usePermissions = (type: UserPermissionTypesEnum|undefined, permissions: UserPermissionsEnum[], resourceId?: number|undefined, parentType?: UserPermissionTypesEnum|undefined, parentResourceId?: number|undefined) => {
    const {hasPermissions} = useContext(PermissionsContext);
    return [hasPermissions(type, permissions, resourceId, parentType, parentResourceId)];
};

export default usePermissions;