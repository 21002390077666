import {UserPermissionsEnum} from "./enums/user-permissions.enum";


export const COMPANY_ADMIN_PERMISSIONS_TEMPLATE = [
    UserPermissionsEnum.Read,
    UserPermissionsEnum.Update,
    UserPermissionsEnum.Delete,
    UserPermissionsEnum.Create,
    UserPermissionsEnum.ReadReports,
    UserPermissionsEnum.AddUser,
    UserPermissionsEnum.CreateUser,
    UserPermissionsEnum.CreateTrial,
    UserPermissionsEnum.RemoveUser,
    UserPermissionsEnum.ActionTasks
];

export const COMPANY_USER_PERMISSIONS_TEMPLATE = [
    UserPermissionsEnum.Read,
    UserPermissionsEnum.Create,
    UserPermissionsEnum.ReadReports,
    UserPermissionsEnum.AddUser,
    UserPermissionsEnum.CreateUser,
    UserPermissionsEnum.CreateTrial,
    UserPermissionsEnum.RemoveUser,
    UserPermissionsEnum.ActionTasks
];

export const TRIAL_ADMIN_PERMISSIONS_TEMPLATE = [
    UserPermissionsEnum.Read,
    UserPermissionsEnum.Update,
    UserPermissionsEnum.ReadParticipants,
    UserPermissionsEnum.ReadReports,
    UserPermissionsEnum.AddUser,
    UserPermissionsEnum.CreateUser,
    UserPermissionsEnum.RemoveUser,
    UserPermissionsEnum.ActionTasks
];

export const TRIAL_USER_PERMISSIONS_TEMPLATE = [
    UserPermissionsEnum.Read,
    UserPermissionsEnum.ReadParticipants,
    UserPermissionsEnum.ReadReports,
    UserPermissionsEnum.ActionTasks
];

export const TRIAL_SPONSOR_PERMISSIONS_TEMPLATE = [
    UserPermissionsEnum.Read,
    UserPermissionsEnum.CreateUser,
    UserPermissionsEnum.ReadReports
];



