export const REPORT_SINGLE_TRIAL_LOAD = '@report/REPORT_SINGLE_TRIAL_LOAD';
export const REPORT_SINGLE_TRIAL_LOADED = '@report/REPORT_SINGLE_TRIAL_LOADED';
export const REPORT_SINGLE_TRIAL_UNLOAD = '@report/REPORT_SINGLE_TRIAL_UNLOAD';
export const REPORT_TRIALS_LOAD = '@report/REPORT_TRIALS_LOAD';
export const REPORT_TRIALS_LOADED = '@report/REPORT_TRIALS_LOADED';
export const REPORT_DISQUALIFICATION_REASONS_LOAD = '@report/REPORT_DISQUALIFICATION_REASONS_LOAD';
export const REPORT_DISQUALIFICATION_REASONS_LOADED = '@report/REPORT_DISQUALIFICATION_REASONS_LOADED';
export const REPORT_WITHDRAWN_REASONS_LOAD = '@report/REPORT_WITHDRAWN_REASONS_LOAD';
export const REPORT_WITHDRAWN_REASONS_LOADED = '@report/REPORT_WITHDRAWN_REASONS_LOADED';
export const REPORT_AWAITING_ACTIONS_LOAD = '@report/REPORT_AWAITING_ACTIONS_LOAD';
export const REPORT_AWAITING_ACTIONS_LOADED = '@report/REPORT_AWAITING_ACTIONS_LOADED';
export const REPORT_ENROLLMENT_TARGET_LOAD = '@report/REPORT_ENROLLMENT_TARGET_LOAD';
export const REPORT_ENROLLMENT_TARGET_LOADED = '@report/REPORT_ENROLLMENT_TARGET_LOADED';
export const REPORT_REFERRAL_TARGET_LOAD = '@report/REPORT_REFERRAL_TARGET_LOAD';
export const REPORT_REFERRAL_TARGET_LOADED = '@report/REPORT_REFERRAL_TARGET_LOADED';

export const REPORT_REGO_TARGET_LOAD = '@report/REPORT_REGO_TARGET_LOAD';
export const REPORT_REGO_TARGET_LOADED = '@report/REPORT_REGO_TARGET_LOADED';
export const REPORT_PERFORMANCE_LOAD = '@report/REPORT_PERFORMANCE_LOAD';
export const REPORT_PERFORMANCE_LOADED = '@report/REPORT_PERFORMANCE_LOADED';
export const REPORT_CONVERSION_RATE_LOAD = '@report/REPORT_CONVERSION_RATE_LOAD';
export const REPORT_CONVERSION_RATE_LOADED = '@report/REPORT_CONVERSION_RATE_LOADED';
export const REPORT_WORKFLOW_LOAD = '@report/REPORT_WORKFLOW_LOAD';
export const REPORT_WORKFLOW_LOADED = '@report/REPORT_WORKFLOW_LOADED';
export const REPORT_PARTICIPANT_JOURNEY_LOAD = '@report/REPORT_PARTICIPANT_JOURNEY_LOAD';
export const REPORT_PARTICIPANT_JOURNEY_LOADED = '@report/REPORT_PARTICIPANT_JOURNEY_LOADED';
export const REPORT_SITES_LOAD = '@report/REPORT_SITES_LOAD';
export const REPORT_SITES_LOADED = '@report/REPORT_SITES_LOADED';
export const REPORT_LOCATIONS_LOAD = '@report/REPORT_LOCATIONS_LOAD';
export const REPORT_LOCATIONS_LOADED = '@report/REPORT_LOCATIONS_LOADED';
export const REPORT_SITES_OVERVIEW_LOAD = '@report/REPORT_SITES_OVERVIEW_LOAD';
export const REPORT_SITES_OVERVIEW_LOADED = '@report/REPORT_SITES_OVERVIEW_LOADED';
export const REPORT_TASK_BY_RANGE_LOAD = '@report/REPORT_TASK_BY_RANGE_LOAD';
export const REPORT_TASK_BY_RANGE_LOADED = '@report/REPORT_TASK_BY_RANGE_LOADED';
export const REPORT_TASK_BY_RANGE_FAILED = '@report/REPORT_TASK_BY_RANGE_FAILED';
export const REPORT_FILTER_LOAD = '@report/REPORT_FILTER_LOAD';
export const REPORT_FILTER_LOADED = '@report/REPORT_FILTER_LOADED';
export const REPORT_MARKETING_SOURCE_LOAD = '@report/REPORT_MARKETING_SOURCE_LOAD';
export const REPORT_MARKETING_SOURCE_LOADED = '@report/REPORT_MARKETING_SOURCE_LOADED';


