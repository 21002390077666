import * as actionTypes from "../../../../store/actions";
import {UserManagementState} from "../interfaces/user-management.state";
import produce from "immer";
import {UserManagementAction} from "../interfaces/user-management.action";
import {TrialPermissionsResourceInterface} from "../interfaces/trial-permissions-resource.interface";
import {UserAssociatedTrialModel} from "../models/user-associated-trial.model";
import {permissionsToRole} from "../../../../shared/permissions/functions/permission-template.function";
import {UserPermissionTypesEnum} from "../../../../shared/permissions/enums/user-permission-types.enum";
import {UserPermissionsEnum} from "../../../../shared/permissions/enums/user-permissions.enum";
import {UserPermission} from "../../../../api/connect";
import {LocationModel} from "../../../../shared/components/location-selectors/models/location.model";
import {PermissionStatusEnum} from "../../trial-management/enums/permission-status.enum";
import {UnknownAction} from "redux";

const initialState = {
    isLoadingUsers: true,
    users: [],
    totalUsers: 0,
    selectedUser: null,
    isLoadingTrials:false,
    trials:[],
    isChangingRole: null
};

const userManagementReducer = (state: UserManagementState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case  actionTypes.USER_MANAGEMENT_TRIAL_REMOVE:
            return produce(state, draft => {
                const trial = draft.trials.find((u: UserAssociatedTrialModel) => u.id === (action as any).removeTrialId);
                if (trial) {
                    trial.isRemoving = true;
                }
            });
        case actionTypes.USER_MANAGEMENT_TRIAL_REMOVED:
            return produce(state, draft => {
                const trial = draft.trials.find((u: UserAssociatedTrialModel) => u.id === (action as any).removeTrialId);
                if (trial) {
                    trial.isRemoving = false;
                }
                draft.trials = draft.trials.filter((t: UserAssociatedTrialModel) => t.id !== (action as any).removeTrialId);
            });
        case actionTypes.USER_MANAGEMENT_ROLE_CHANGE:
            return produce(state, draft => {
                const trials = JSON.parse(JSON.stringify(draft.trials)); // force state change
                draft.isChangingRole = (action as any).changingUserRole.id;
                const trial = trials.find((u: UserAssociatedTrialModel) => u.id === (action as any).changingUserRole.id);
                if (trial) {
                    trial.isChangingRole = true;
                    trial.locations = [];
                }
                draft.trials = trials;
            });
        case actionTypes.USER_MANAGEMENT_ROLE_CHANGED:
            return produce(state, draft => {
                const trial = draft.trials.find((u: UserAssociatedTrialModel) => u.id === (action as any).changingUserRole.id);
                if (trial) {
                    trial.isChangingRole = false;
                    trial.role = (action as any).changingUserRole.newRole;
                    trial.permissions = (action as any).changingUserRole.updatedPermissions;
                }
                draft.isChangingRole = null;
            });
        case actionTypes.USER_MANAGEMENT_LOCATION_CHANGED:
            return produce(state, draft => {
                const trial = draft.trials.find((u: UserAssociatedTrialModel) => u.id === (action as any).changingUserRole.id);
                if (trial) {
                    trial.permissions = (action as any).changingUserRole.updatedPermissions;
                }
            });
        case actionTypes.USER_MANAGEMENT_TRIALS_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrials = true;
            });
        case actionTypes.USER_MANAGEMENT_TRIALS_LOADED:
            return produce(state, draft => {

                const tempTrialPermissions: TrialPermissionsResourceInterface[] = [];
                const trialPermissions = (action as any).trials;

                for (let i = 0; i < trialPermissions.length; i++) {
                    const hasTrial = tempTrialPermissions.length && tempTrialPermissions.find(t => t.id === trialPermissions[i].resourceId);
                    if (!hasTrial){
                        tempTrialPermissions.push({
                            id: trialPermissions[i].resourceId,
                            name: trialPermissions[i].resourceName,
                            identifier: trialPermissions[i].resourceIdentifier,
                            type: trialPermissions[i].type as unknown as UserPermissionTypesEnum,
                            permissions: [trialPermissions[i].permission as unknown as UserPermissionsEnum],
                            status: trialPermissions[i].status as unknown as PermissionStatusEnum,
                        })
                    } else {
                        hasTrial.permissions.push(trialPermissions[i].permission as unknown as UserPermissionsEnum);
                    }
                }

                draft.trials = tempTrialPermissions.length ? tempTrialPermissions.filter(t => t.type === UserPermissionTypesEnum.Trial).map((t: TrialPermissionsResourceInterface) => {
                    return new UserAssociatedTrialModel(
                        t.id,
                        t.name,
                        t.identifier,
                        permissionsToRole(t.permissions, UserPermissionTypesEnum.Trial),
                        trialPermissions.filter((p:any) => p.parentResourceId as number > 0 && p.resourceId === t.id).map((p: UserPermission) => {
                            const location = new LocationModel();
                            location.id = p.parentResourceId;
                            location.name = p.parentResourceName as string;
                            return location;
                        }),
                        (action as any).sites.find((s:any) => s.trialId === t.id).sites.map((s: any) => {
                            const location = new LocationModel();
                            location.id = s.id;
                            location.name= s.name;
                            location.addressLine1= s.addressLine1;
                            location.addressLine2 = s.addressLine2;
                            location.postcode = s.postcode;
                            location.state = s.state;
                            return location;
                        }),
                        'Active',
                        trialPermissions.filter((p:UserPermission) => p.resourceId === t.id),
                        t.status,
                        false,
                        false)
                }) : [];

                draft.isLoadingTrials = false;
            });
        case actionTypes.USER_MANAGEMENT_LOAD:
            return produce(state, draft => {
                draft.isLoadingUsers = true;
            });
        case actionTypes.USER_MANAGEMENT_LOADED:
            return produce(state, draft => {
                draft.isLoadingUsers = false;
                draft.users = (action as any).users;
                draft.totalUsers = (action as any).totalUsers;
            });
        case actionTypes.USER_MANAGEMENT_SELECTED_USER:
            return produce(state, draft => {
                draft.selectedUser = (action as any).user;
            });
        case actionTypes.USER_MANAGEMENT_UNSELECT_USER:
            return produce(state, draft => {
                draft.selectedUser = null;
            });
        default:
            return state;
    }
};

export default userManagementReducer;