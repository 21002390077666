import {FileModel} from "../../../file-library/models/file.model";

export class TrialAttachmentsListModel extends FileModel {

    constructor(public id: number,
                public imageKey: string,
                public description: string,
                public uploadDate: string,
                public patientCognito: string,
                public statuses: any[]) {
        super(id,imageKey, description,uploadDate,-1, patientCognito,statuses);
    }

}
