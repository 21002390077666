/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationTypes } from '../models/NotificationTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * @param userCognitoSub 
     * @param type 
     * @param notes 
     * @param trialId 
     * @param siteId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiNotificationsGetPreview(
userCognitoSub?: string,
type?: NotificationTypes,
notes?: string,
trialId?: number,
siteId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Notifications/GetPreview',
            query: {
                'userCognitoSub': userCognitoSub,
                'type': type,
                'notes': notes,
                'trialId': trialId,
                'siteId': siteId,
            },
        });
    }

}
