import {FileModel} from "./file.model";
import {FileRequestStatusEnum} from "../../../shared/enums/file-request-status.enum";

export class RequestModel {

    public status: FileRequestStatusEnum|undefined;
    public reason = '';

    constructor(public id: number,
                public name: string,
                public participantCognito: string,
                public participantFirstName: string,
                public participantLastName: string,
                public statuses: any[],
                public files:  FileModel[]) {
        this.setStatus(statuses);
    }

    setStatus = (statuses: any[]) => {
        if (statuses.length) {

            const lastStatus = statuses.pop();
            this.status = lastStatus.status;

            if (lastStatus.status === FileRequestStatusEnum.Rejected || lastStatus.status === FileRequestStatusEnum.ReRequest) {
                    this.reason = lastStatus.rejectionReason;
            }
        }
    }
}