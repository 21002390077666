import {RecordStatuses, UserPermission} from "../../../../api/connect";

export class CompanyUserListModel {
  constructor(public id: string | null | undefined,
              public name: string,
              public initials: string,
              public email: string | null | undefined,
              public role: any,
              public trials: number,
              public status: RecordStatuses | undefined,
              public permissions: UserPermission[],
              public isChangingRole: boolean,
              public isRemoving: boolean) {}
}
