export const options = [
  {
    value: '2022-09-19T00:00:00',
    label: '12am',
  },
  {
    value: '2022-09-19T01:00:00',
    label: '1am',
  },
  {
    value: '2022-09-19T02:00:00',
    label: '2am',
  },
  {
    value: '2022-09-19T03:00:00',
    label: '3am',
  },
  {
    value: '2022-09-19T04:00:00',
    label: '4am',
  },
  {
    value: '2022-09-19T05:00:00',
    label: '5am',
  },
  {
    value: '2022-09-19T06:00:00',
    label: '6am',
  },
  {
    value: '2022-09-19T07:00:00',
    label: '7am',
  },
  {
    value: '2022-09-19T08:00:00',
    label: '8am',
  },
  {
    value: '2022-09-19T09:00:00',
    label: '9am',
  },
  {
    value: '2022-09-19T10:00:00',
    label: '10am',
  },
  {
    value: '2022-09-19T11:00:00',
    label: '11am',
  },
  {
    value: '2022-09-19T12:00:00',
    label: '12pm',
  },
  {
    value: '2022-09-19T13:00:00',
    label: '1pm',
  },
  {
    value: '2022-09-19T14:00:00',
    label: '2pm',
  },
  {
    value: '2022-09-19T15:00:00',
    label: '3pm',
  },
  {
    value: '2022-09-19T16:00:00',
    label: '4pm',
  },
  {
    value: '2022-09-19T17:00:00',
    label: '5pm',
  },
  {
    value: '2022-09-19T18:00:00',
    label: '6pm',
  },
  {
    value: '2022-09-19T19:00:00',
    label: '7pm',
  },
  {
    value: '2022-09-19T20:00:00',
    label: '8pm',
  },
  {
    value: '2022-09-19T21:00:00',
    label: '9pm',
  },
  {
    value: '2022-09-19T22:00:00',
    label: '10pm',
  },
  {
    value: '2022-09-19T23:00:00',
    label: '11pm',
  },
];

export const initialTimes = [
  {
    day: 'Mondays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Tuesdays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Wednesdays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Thursdays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Fridays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Saturdays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
  {
    day: 'Sundays',
    from: '2022-09-19T08:00:00',
    to: '2022-09-19T17:00:00',
    selected: false,
  },
];