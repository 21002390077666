import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {RecordStatusesEnum} from "../../shared/enums/record-statuses.enum";
import {REDIRECT_TO_KEY} from "../../shared/constants";
import {isPathValid} from "../is-path-valid";
import {hasValidProfile} from "../../shared/utils/has-valid-profile";
import config from "../../config";
import {RoleEnum} from "../../shared/enums/roleEnum";


const AuthGuard = ({component}:{component:any}) => {
    const { isLoggedIn, isRegistered, user } = useAuth() as any;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        if (!isLoggedIn) {
            if (location && isPathValid(location.pathname)){
                localStorage.setItem(REDIRECT_TO_KEY,location.pathname);
            }
            navigate("/landing", {replace: true});
        }

        if (isLoggedIn && !isRegistered) {
            navigate("/register", {replace: true});
        }

        if (isLoggedIn && isRegistered && (user?.recordStatus === RecordStatusesEnum.Unverified || !hasValidProfile(user?.locale, user?.phoneNumber))) {
            return navigate("/profile-update", {replace: true});
        }
        
    }, [navigate, user, location, isLoggedIn, isRegistered]);


    return <React.Fragment>{component}</React.Fragment>;

};

export default AuthGuard;
