export enum TaskStatusEnum {
    NOT_STARTED,
    AWAITING_ACTION,
    PASSED,
    FAILED,
    SKIPPED,
    DISQUALIFIED,
    ATTEMPTED,
    DONE,
    WITHDRAWN,
    PENDING,
    REFERRED,
    RANDOMISED,
    NOTE_GIVEN,
    PENDING_REVIEW,
    IN_PROGRESS
}