export const TRIAL_MANAGEMENT_LOAD = '@trial-management/TRIAL_MANAGEMENT_LOAD';
export const TRIAL_MANAGEMENT_LOADED = '@trial-management/TRIAL_MANAGEMENT_LOADED';
export const TRIAL_MANAGEMENT_SELECT_TRIAL = '@trial-management/TRIAL_MANAGEMENT_SELECT_TRIAL';
export const TRIAL_MANAGEMENT_SELECTED_TRIAL = '@trial-management/TRIAL_MANAGEMENT_SELECTED_TRIAL';
export const TRIAL_MANAGEMENT_UNSELECT_TRIAL = '@trial-management/TRIAL_MANAGEMENT_UNSELECT_TRIAL';
export const TRIAL_MANAGEMENT_USERS_LOAD = '@trial-management/TRIAL_MANAGEMENT_USERS_LOAD';
export const TRIAL_MANAGEMENT_USERS_LOADED = '@trial-management/TRIAL_MANAGEMENT_USERS_LOADED';
export const TRIAL_MANAGEMENT_ROLE_CHANGE = '@trial-management/TRIAL_MANAGEMENT_ROLE_CHANGE';
export const TRIAL_MANAGEMENT_ROLE_CHANGED = '@trial-management/TRIAL_MANAGEMENT_ROLE_CHANGED';
export const TRIAL_MANAGEMENT_UPDATE_DETAILS = '@trial-management/TRIAL_MANAGEMENT_UPDATE_DETAILS';
export const TRIAL_MANAGEMENT_UPDATED_DETAILS = '@trial-management/TRIAL_MANAGEMENT_UPDATED_DETAILS';
export const TRIAL_MANAGEMENT_COMPANY_SITES_LOAD = '@trial-management/TRIAL_MANAGEMENT_COMPANY_SITES_LOAD';
export const TRIAL_MANAGEMENT_COMPANY_SITES_LOADED = '@trial-management/TRIAL_MANAGEMENT_COMPANY_SITES_LOADED';
export const TRIAL_MANAGEMENT_SITES_LOAD = '@trial-management/TRIAL_MANAGEMENT_SITES_LOAD';
export const TRIAL_MANAGEMENT_SITES_LOADED = '@trial-management/TRIAL_MANAGEMENT_SITES_LOADED';
export const TRIAL_MANAGEMENT_SITE_STATUS_CHANGED = '@trial-management/TRIAL_MANAGEMENT_SITE_STATUS_CHANGED';
export const TRIAL_MANAGEMENT_UNLINK_SITE = '@trial-management/TRIAL_MANAGEMENT_UNLINK_SITE';
export const TRIAL_MANAGEMENT_SITE_UNLINKED = '@trial-management/TRIAL_MANAGEMENT_SITE_UNLINKED';
export const TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED_RESET = '@trial-management/TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED_RESET';
export const TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED = '@trial-management/TRIAL_MANAGEMENT_SITE_UNLINKED_FAILED';
export const TRIAL_MANAGEMENT_LINK_SITE = '@trial-management/TRIAL_MANAGEMENT_LINK_SITE';
export const TRIAL_MANAGEMENT_SITE_LINKED = '@trial-management/TRIAL_MANAGEMENT_SITE_LINKED';
export const TRIAL_MANAGEMENT_SITE_STATUSES_LOAD = '@trial-management/TRIAL_MANAGEMENT_SITE_STATUSES_LOAD';
export const TRIAL_MANAGEMENT_SITE_STATUSES_LOADED = '@trial-management/TRIAL_MANAGEMENT_SITE_STATUSES_LOADED';
export const TRIAL_MANAGEMENT_TRIAL_STATUSES_LOAD = '@trial-management/TRIAL_MANAGEMENT_TRIAL_STATUSES_LOAD';
export const TRIAL_MANAGEMENT_TRIAL_STATUSES_LOADED = '@trial-management/TRIAL_MANAGEMENT_TRIAL_STATUSES_LOADED';
export const TRIAL_MANAGEMENT_SAVE_INVITED_USERS = '@trial-management/TRIAL_MANAGEMENT_SAVE_INVITED_USERS';
export const TRIAL_MANAGEMENT_INVITED_USERS_SAVED = '@trial-management/TRIAL_MANAGEMENT_INVITED_USERS_SAVED';
export const TRIAL_MANAGEMENT_USER_REMOVE = '@trial-management/TRIAL_MANAGEMENT_USER_REMOVE';
export const TRIAL_MANAGEMENT_USER_REMOVED = '@trial-management/TRIAL_MANAGEMENT_USER_REMOVED';
export const TRIAL_MANAGEMENT_LOCATION_CHANGE = '@trial-management/TRIAL_MANAGEMENT_LOCATION_CHANGE';
export const TRIAL_MANAGEMENT_LOCATION_CHANGED = '@trial-management/TRIAL_MANAGEMENT_LOCATION_CHANGED';
export const TRIAL_MANAGEMENT_DECLINE_INVITATION = '@trial-management/TRIAL_MANAGEMENT_DECLINE_INVITATION';
export const TRIAL_MANAGEMENT_INVITATION_DECLINED = '@trial-management/TRIAL_MANAGEMENT_INVITATION_DECLINED';

export const TRIAL_MANAGEMENT_DQ_REASONS_LOAD = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_LOAD';
export const TRIAL_MANAGEMENT_DQ_REASONS_LOADED = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_LOADED';

export const TRIAL_MANAGEMENT_DQ_REASONS_TOGGLE = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_TOGGLE';

export const TRIAL_MANAGEMENT_DQ_REASONS_TOGGLED = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_TOGGLED';

export const TRIAL_MANAGEMENT_DQ_REASONS_ADD = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_ADD';

export const TRIAL_MANAGEMENT_DQ_REASONS_ADDED = '@trial-management/TRIAL_MANAGEMENT_DQ_REASONS_ADDED';


export const TRIAL_MANAGEMENT_WD_REASONS_LOAD = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_LOAD';
export const TRIAL_MANAGEMENT_WD_REASONS_LOADED = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_LOADED';
export const TRIAL_MANAGEMENT_WD_REASONS_TOGGLE = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_TOGGLE';
export const TRIAL_MANAGEMENT_WD_REASONS_TOGGLED = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_TOGGLED';
export const TRIAL_MANAGEMENT_WD_REASONS_ADD = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_ADD';
export const TRIAL_MANAGEMENT_WD_REASONS_ADDED = '@trial-management/TRIAL_MANAGEMENT_WD_REASONS_ADDED';

export const TRIAL_MANAGEMENT_LOCATION_TARGET_CHANGE = '@trial-management/TRIAL_MANAGEMENT_LOCATION_TARGET_CHANGE';
export const TRIAL_MANAGEMENT_LOCATION_TARGET_CHANGED = '@trial-management/TRIAL_MANAGEMENT_LOCATION_TARGET_CHANGED';