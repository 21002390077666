export const NotificationTypeEnum = Object.freeze({
    All_ForgotPassword: 0,
    All_VerificationLink: 1,
    Site_DailySummary: 2,
    Site_NewReferral: 3,
    Site_NewTeamMember: 4,
    Site_AddedToSite: 5,
    Site_AddedToTrial: 6,
    Site_RequestFile: 17,
    Participant_Disqualified: 7,
    Participant_Withdrawn: 8,
    Participant_Referred: 9,
    Participant_ReReferred: 10,
    Participant_AppointmentBooked: 11,
    Participant_AppointmentReminder: 12,
    Participant_AppointmentChanged: 13,
    Participant_AppointmentCancelled: 14,
    Participant_PreferredTime: 15,
    All_AddedToCompany: 16
});
