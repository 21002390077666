export const COMPANY_PARTICIPANT_IMPORTED_LOAD = '@participant-management/COMPANY_PARTICIPANT_IMPORTED_LOAD';
export const COMPANY_PARTICIPANT_IMPORTED_LOADED = '@participant-management/COMPANY_PARTICIPANT_IMPORTED_LOADED';
export const THERAPEUTICAREA_LOAD = '@participant-management/THERAPEUTICAREA_LOAD';
export const THERAPEUTICAREA_LOADED = '@participant-management/THERAPEUTICAREA_LOADED';
export const PARTICIPANT_IMPORTED_RECORD_LOAD = '@participant-management/PARTICIPANT_IMPORTED_RECORD_LOAD';
export const PARTICIPANT_IMPORTED_RECORD_LOADED = '@participant-management/PARTICIPANT_IMPORTED_RECORD_LOADED';
export const PARTICIPANT_IMPORTED_RECORD_DELETE = '@participant-management/PARTICIPANT_IMPORTED_RECORD_DELETE';
export const PARTICIPANT_IMPORTED_RECORD_DELETED = '@participant-management/PARTICIPANT_IMPORTED_RECORD_DELETED';
export const COMPANY_PARTICIPANT_LOAD = '@participant-management/COMPANY_PARTICIPANT_LOAD';
export const COMPANY_PARTICIPANT_LOADED = '@participant-management/COMPANY_PARTICIPANT_LOADED';
export const TRIAL_USER_LOADED = '@participant-management/TRIAL_USER_LOADED';
