import styled from "@emotion/styled";
import {Alert} from "@mui/material";
import {ConnectAlertInterface} from "./connect-alert.interface";

const nativeComponent = (cai: ConnectAlertInterface) => {
    const { fullWidth, ...props } = cai;
    return <Alert {...props} />
}

export const ConnectAlert = styled((props: ConnectAlertInterface) => (
    nativeComponent(props)
))(({ theme, fullWidth }: any) => ({
    '&.MuiAlert-root': {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        borderRadius: '.3rem',
        width: (fullWidth) ? '100%' : 'fit-content',
        padding: '1rem',
        color: '#000',
        '& .MuiAlert-icon': {
            padding: '0.5rem 0.7rem 0.5rem 0.4rem',
            fontSize: '2rem'
        },
        '& .MuiAlert-message': {
            width: '100%'
        },
        '&.MuiAlert-standardSuccess': {
            '& .MuiAlertTitle-root': {
                color: theme.palette.success.main
            },
            '& .MuiAlert-icon':{
                color: theme.palette.success.main
            },
            backgroundColor: '#fff',
            borderLeft: `4px solid ${theme.palette.success.main}`,
        },
        '&.MuiAlert-standardError': {
            '& .MuiAlertTitle-root': {
                color: theme.palette.error.main
            },
            '& .MuiAlert-icon':{
                color: theme.palette.error.main
            },
            backgroundColor: '#fff',
            borderLeft: `4px solid ${theme.palette.error.main}`,
        },
        '&.MuiAlert-standardWarning': {
            '& .MuiAlertTitle-root': {
                color: theme.palette.warning.main
            },
            '& .MuiAlert-icon':{
                color: theme.palette.warning.main
            },
            backgroundColor: '#fff',
            borderLeft: `4px solid ${theme.palette.warning.main}`,
        },
        '&.MuiAlert-standardInfo': {
            '& .MuiAlertTitle-root': {
                color: theme.palette.info.main
            },
            '& .MuiAlert-icon':{
                color: theme.palette.info.main
            },
            backgroundColor: '#fff',
            borderLeft: `4px solid ${theme.palette.info.main}`,
        }
    }
}));