
import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {NotificationPanelState} from "../interfaces/notification-panel.state";
import {NotificationPanelAction} from "../interfaces/notification-panel.action";
import {CommentInterface} from "../interfaces/comment.interface";
import {UnknownAction} from "redux";

const initialState = {
    isLoadingComments: true,
    unreadOnly: false,
    comments: []
};

const notificationPanelReducer = (state: NotificationPanelState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case actionTypes.NOTIFICATION_PANEL_MARK_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = c.id === (action as any).commentId ? true : c.isRead;
                });
            });
        case actionTypes.NOTIFICATION_PANEL_MARKED_ALL_AS_READ:
            return produce(state, draft => {
                draft.comments.map((c: CommentInterface) => {
                    return c.isRead = (action as any).commentIds.includes(c.id) ? true : c.isRead;
                });
            });
        case actionTypes.NOTIFICATION_PANEL_COMMENTS_LOAD:
            return produce(state, draft => {
                draft.isLoadingComments = true;
                draft.unreadOnly = (action as any).unreadOnly;
        });
        case actionTypes.NOTIFICATION_PANEL_COMMENTS_LOADED:
            return produce(state, draft => {
                draft.comments = (action as any).comments;
                draft.isLoadingComments = false;
            });
    default:
        return state;
    }
};

export default notificationPanelReducer;