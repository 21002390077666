/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCommentRequest } from '../models/CreateCommentRequest';
import type { GetCommentRequest } from '../models/GetCommentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentsService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiCommentsCreateComment(
requestBody?: CreateCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Comments/CreateComment',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param commentId 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCommentsRemoveComment(
commentId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Comments/RemoveComment',
            query: {
                'commentId': commentId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param unreadOnly 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCommentsGetCommentRead(
userCognitoSub?: string,
unreadOnly?: boolean,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Comments/GetCommentRead',
            query: {
                'userCognitoSub': userCognitoSub,
                'unreadOnly': unreadOnly,
            },
        });
    }

    /**
     * @param unreadOnly 
     * @param searchUser 
     * @param searchText 
     * @param searchTrial 
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCommentsGetCommentReadByFilter(
unreadOnly?: boolean,
searchUser?: string,
searchText?: string,
searchTrial?: number,
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Comments/GetCommentReadByFilter',
            query: {
                'unreadOnly': unreadOnly,
                'searchUser': searchUser,
                'searchText': searchText,
                'searchTrial': searchTrial,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @param commentId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCommentsGetComment(
commentId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Comments/GetComment',
            query: {
                'commentId': commentId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiCommentsGetComments(
requestBody?: GetCommentRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Comments/GetComments',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param authorCognitoSub 
     * @param commentReadId 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCommentsUpdateReadComment(
authorCognitoSub?: string,
commentReadId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Comments/UpdateReadComment',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'commentReadId': commentReadId,
            },
        });
    }

}
