import {UserPermission} from "../../../../api/connect";
import {LocationModel} from "../../../../shared/components/location-selectors/models/location.model";
import {PermissionStatusEnum} from "../../trial-management/enums/permission-status.enum";

export class UserAssociatedTrialModel {
   constructor(public id: number | undefined,
               public name: string | null | undefined,
               public trialIdentifier: string | null | undefined,
               public role: any,
               public locations: LocationModel[],
               public associatedLocations: LocationModel[],
               public status: string,
               public permissions: UserPermission[],
               public invitationStatus: PermissionStatusEnum,
               public isChangingRole: boolean,
               public isRemoving: boolean) {
   }
}