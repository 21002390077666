import React from 'react';
import usePermissions from "../../hooks/use-permissions.hook";
import {UserPermissionsEnum} from "../../shared/permissions/enums/user-permissions.enum";
import {UserPermissionTypesEnum} from "../../shared/permissions/enums/user-permission-types.enum";
import {Chip, Stack} from "@mui/material";
import {Lock} from "@mui/icons-material";
import config from "../../config";

const PermissionRestricted = ({type, permissions, children, resourceId, parentType, parentResourceId}: {type: UserPermissionTypesEnum, permissions: UserPermissionsEnum[], children: any, resourceId?: number, parentType?: UserPermissionTypesEnum, parentResourceId?: number}) => {

    const [allowed] = usePermissions(type, permissions, resourceId, parentType, parentResourceId);

    return (
        <>
            { allowed && children }
            {!allowed && !config.permissions.transparent && (<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{padding: '1rem'}}><Chip icon={<Lock />} label="Permission required!" /></Stack>)}
        </>
    );
};

export default PermissionRestricted;