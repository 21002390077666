

export class ParticipantTableModel {

    id = '';
    cognitoSub = '';
    participantName = '';
    dateReferred: string|null = '';

    dateOfTrialRegistration: any;
    siteReferredToId: number|null = null;
    phoneNumber = '';
    email = '';
    preferredTimesPreference: any[] = [];

    //tasks
    [key: string]: any;
}
