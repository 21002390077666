/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditHistoryTypes } from '../models/AuditHistoryTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditService {

    /**
     * @param type 
     * @param trialId 
     * @param userCognitoSub 
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiAuditGetAuditHistories(
type?: AuditHistoryTypes,
trialId?: number,
userCognitoSub?: string,
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Audit/GetAuditHistories',
            query: {
                'type': type,
                'trialId': trialId,
                'userCognitoSub': userCognitoSub,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @param type 
     * @param trialId 
     * @param userCognitoSub 
     * @returns binary OK
     * @throws ApiError
     */
    public static getApiAuditDownloadHistoryCsv(
type?: AuditHistoryTypes,
trialId?: number,
userCognitoSub?: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Audit/DownloadHistoryCSV',
            query: {
                'type': type,
                'trialId': trialId,
                'userCognitoSub': userCognitoSub,
            },
        });
    }

}
