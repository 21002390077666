import React, {} from 'react';
import useRoles from "../../hooks/use-parent-roles.hook";
import {RoleEnum} from "../../shared/enums/role.enum";

const RoleRestricted = ({role, children}: {role: RoleEnum[], children: any}) => {

    const [allowed] = useRoles(role);

    return (
        <>
            {allowed && children}
        </>
    );
};

export default RoleRestricted;