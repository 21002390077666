export enum UserPermissionsEnum {
    Read,
    Update,
    Create,
    Delete,
    CreateTrial,
    CreateUser,
    AddUser,
    RemoveUser,
    ReadParticipants,
    ReadReports,
    ActionTasks
}