import {
    LocationTargetsInterface
} from "../../../../views/settings/trial-management/interfaces/location-targets.interface";

export class LocationModel {
    id: number | undefined;
    name: string | undefined;
    addressLine1: string | undefined;
    addressLine2: string | undefined;
    postcode: string | undefined;
    state: string | undefined;
    locationTargets: LocationTargetsInterface[] | undefined;
    refTarget: number | undefined;
    enrTarget: number | undefined;
}