import {makeStyles} from "@mui/styles";

export const useNotificationPanelStyles = makeStyles((theme: any) => ({
    listContainer:{
        padding: 0
    },
    listSubHeader:{
        paddingLeft: '1.6rem',
        textTransform: 'uppercase',
        color: '#616161'
    },
    commentContainer: {
        padding: '.8rem 1.6rem',
        '&:hover': {
            '& .participantName': {
                textDecoration: 'underline'
            },
            '& .trialName': {
                textDecoration: 'underline'
            }
        }
    },
    markAllLink: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 9,
        padding: '.8rem 1.5rem',
        cursor: 'pointer'
    },
    cardContent: {
        overflowY: 'auto',
        padding: '0',
        maxHeight: '28rem',
        position: 'relative'
    },
    card: {
        boxShadow: '0 1px 10px 0 rgb(0 0 0 / .1), 0 1px 11px -1px rgb(0 0 0 / .1)'
    }
}));