import {NotificationBarProps} from "./notification-bar.props";
import {Box, Button, Stack} from "@mui/material";
import {useTheme} from "@mui/styles";
import {useNotificationBarStyles} from "./notification-bar.styles";
import {Campaign} from "@mui/icons-material";
import {useState} from "react";
import React from "react";

const NotificationBarComponent = (props: NotificationBarProps) => {

    const [hide, setHide] = useState(true);
    const theme = useTheme();
    const classes = useNotificationBarStyles(theme);
    const LOCALSTORAGE_KEY = 'dismissNotificationBar.v1';

    const onOk = () => {
        setHide(true);
        localStorage.setItem(LOCALSTORAGE_KEY, 'true');
        props.onShow(false);
    }

    // useEffect(() => {
    //     const dismissNotificationBar = localStorage.getItem(LOCALSTORAGE_KEY);
    //     if (dismissNotificationBar){
    //         setHide(true);
    //         props.onShow(false);
    //     } else {
    //         setHide(false);
    //         props.onShow(true);
    //     }
    // },[props]);

    return (
        <React.Fragment>
            { !hide && <Box className={classes.container}>
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
                    <Box className={classes.icon}><Campaign /></Box>
                    <Box className={classes.message}><b>evrimaconnect.com</b> now owns <b>evrilink.com</b>. We're the same great company but when you land on the website you'll be redirected to <b>evrimaconnect.com</b> as the primary URL.</Box>
                    <Button variant={"contained"} size={"small"} className={classes.okButton} onClick={()=> onOk()}>Got it</Button>
                </Stack>
            </Box> }
        </React.Fragment>
    );
}

export default NotificationBarComponent;