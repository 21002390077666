export const FILE_LIBRARY_LOAD = '@file-library/FILE_LIBRARY_LOAD';
export const FILE_LIBRARY_LOADED = '@file-library/FILE_LIBRARY_LOADED';
export const FILE_LIBRARY_UNLOAD = '@file-library/FILE_LIBRARY_UNLOAD';
export const FILE_LIBRARY_REQUEST_LOAD = '@file-library/FILE_LIBRARY_REQUEST_LOAD';
export const FILE_LIBRARY_REQUEST_LOADED = '@file-library/FILE_LIBRARY_REQUEST_LOADED';
export const FILE_LIBRARY_REQUEST_UNLOAD = '@file-library/FILE_LIBRARY_REQUEST_UNLOAD';
export const FILE_LIBRARY_FILE_STATUS_SAVE = '@file-library/FILE_LIBRARY_FILE_STATUS_SAVE';
export const FILE_LIBRARY_FILE_STATUS_SAVED = '@file-library/FILE_LIBRARY_FILE_STATUS_SAVED';
export const FILE_LIBRARY_REQUEST_STATUS_SAVE = '@file-library/FILE_LIBRARY_REQUEST_STATUS_SAVE';
export const FILE_LIBRARY_REQUEST_STATUS_SAVED = '@file-library/FILE_LIBRARY_REQUEST_STATUS_SAVED';
export const FILE_LIBRARY_FILE_DELETE = '@file-library/FILE_LIBRARY_FILE_DELETE';
export const FILE_LIBRARY_FILE_DELETED = '@file-library/FILE_LIBRARY_FILE_DELETED';