import useAuth from '../../hooks/useAuth';
import {useNavigate} from "react-router-dom";
import React, {useEffect} from 'react';
import {REDIRECT_TO_KEY} from "../../shared/constants";
import {RoleEnum} from "../../shared/enums/role.enum";

const PublicGuard = ({component}:{component:any}) => {
    const { isLoggedIn, user, isRegistered } = useAuth() as any;
    const navigate = useNavigate();
    
    

    useEffect(() => {

        if (isLoggedIn && !isRegistered) {
            navigate("/register", {replace: true});
        } else if (isLoggedIn && user && user?.role) {
            const hasRedirect = localStorage.getItem(REDIRECT_TO_KEY);
            if (!!hasRedirect) {
                localStorage.removeItem(REDIRECT_TO_KEY);
                navigate(hasRedirect, {replace: true});

            } else {
                navigate([RoleEnum.PARTICIPANT].includes(user.role) ? "/your-trials" : "/dashboard", {replace: true});
            }
        }
        
    }, [isLoggedIn, isRegistered, navigate, user]);
    
    return <React.Fragment>{component}</React.Fragment>;
};

export default PublicGuard;
