// theme constant
import {ParticipantStatusEnum} from "../shared/enums/participantStatusEnum";
import {TaskStatusEnum} from "../shared/enums/taskStatusEnum";
import {AccessTime, Close, Done, Remove, Replay} from "@mui/icons-material";
import React from "react";

export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const statuses = [
    { index: TaskStatusEnum.IN_PROGRESS, label: 'In Progress', icon: <AccessTime color="secondary" />},
    { index: TaskStatusEnum.AWAITING_ACTION, label: 'Awaiting action', icon: <AccessTime color="secondary" />},
    { index: TaskStatusEnum.PENDING_REVIEW, label: 'Pending review', icon: <AccessTime color="warning" />},
    { index: TaskStatusEnum.ATTEMPTED, label: 'Attempted', icon: <AccessTime color="secondary" />},
    { index: TaskStatusEnum.RESCHEDULED, label: 'Rescheduled', icon: <AccessTime color="secondary" />},
    { index: TaskStatusEnum.REWIND, label: 'Rewind', icon: <Replay color="primary" />},
    { index: TaskStatusEnum.DONE, label: 'Done', icon: <Done color="success" />},
    { index: TaskStatusEnum.PASSED, label: 'Passed', icon: <Done color="success" />},
    { index: TaskStatusEnum.DISQUALIFIED, label: 'Disqualified', icon: <Close color="error" />},
    { index: TaskStatusEnum.WITHDRAWN, label: 'Withdrawn', icon: <Close color="error" />},
    { index: TaskStatusEnum.SKIPPED, label: 'Skipped', icon: <Remove color="dark" />},
    { index: TaskStatusEnum.PENDING, label: 'Pending', icon: <AccessTime color="warning" />},
    { index: TaskStatusEnum.NOT_STARTED, label: 'Not started', icon: <Remove color="dark" />},
    { index: TaskStatusEnum.REFERRED, label: 'Referred', icon: <Done color="success" />},
    { index: TaskStatusEnum.RANDOMISED, label: 'Randomised', icon: <Done color="success" />},
    { index: TaskStatusEnum.NOTE_GIVEN, label: 'Not given', icon: <Close color="error" />},
];
export const overallStatuses = [
    {
        index: ParticipantStatusEnum.IN_PROGRESS,
        label: 'In Progress',
        color: 'info'
    },
    {
        index: ParticipantStatusEnum.REFERRED,
        label: 'Referred',
        color: 'success'
    },
    {
        index: ParticipantStatusEnum.RANDOMISED,
        label: 'Randomised',
        color: 'success'
    },
    {
        index: ParticipantStatusEnum.WITHDRAWN,
        label: 'Withdrawn',
        color: 'error'
    },
    {
        index: ParticipantStatusEnum.DISQUALIFIED,
        label: 'Disqualified',
        color: 'error'
    }
]
