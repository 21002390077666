/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JotformStatus } from '../models/JotformStatus';
import type { SubmitSurveyRequest } from '../models/SubmitSurveyRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JotformService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiJotformSubmitSurvey(
requestBody?: SubmitSurveyRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Jotform/SubmitSurvey',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param submissionId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiJotformGetSubmission(
submissionId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Jotform/GetSubmission',
            query: {
                'submissionId': submissionId,
            },
        });
    }

    /**
     * @param submissionId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiJotformGetSubmissionById(
submissionId?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Jotform/GetSubmissionById',
            query: {
                'submissionId': submissionId,
            },
        });
    }

    /**
     * @param patientId 
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiJotformGetSubmissionByPatientId(
patientId?: number,
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Jotform/GetSubmissionByPatientId',
            query: {
                'patientId': patientId,
                'trialId': trialId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiJotformGetSubmissionOutcomeByPatientSub(
userCognitoSub?: string,
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Jotform/GetSubmissionOutcomeByPatientSub',
            query: {
                'userCognitoSub': userCognitoSub,
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @param status 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiJotformToggleJotformStatus(
trialId?: number,
status?: JotformStatus,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Jotform/ToggleJotformStatus',
            query: {
                'trialId': trialId,
                'status': status,
            },
        });
    }

    /**
     * @param trialId 
     * @param options 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiJotformUpdatePreferredSitesQuestion(
trialId?: number,
options?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Jotform/UpdatePreferredSitesQuestion',
            query: {
                'trialId': trialId,
                'options': options,
            },
        });
    }

}
