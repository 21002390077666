
//PROD

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    prod: true,
    basename: '',
    defaultPath: '/',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light',
    idleTimeout: 1200000, // 20 minutes in milliseconds
    presetColor: 'evrima', // default, theme1, theme2, theme3, theme4, theme5, theme6, evrima
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    ga: {
        id: 'G-MWJTVS3XN3'
    },
    aws: {
        baseUrl : 'https://kzxg5dnn46.execute-api.ap-southeast-2.amazonaws.com/Prod',
        xApiKey : 'zYtax8cpwg38lltMMa1Kr8Vur10xEwbW7DPSfC0Q',
        signalr: 'https://kzxg5dnn46.execute-api.ap-southeast-2.amazonaws.com/Prod/messagehub'
    },
    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
    awsAuth : {
        baseUrl : 'https://4b5mgk6kji.execute-api.ap-southeast-2.amazonaws.com/Prod',
        jwksAddressTemplate : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_7BjJeRi9X/.well-known/jwks.json',
        jwksAddress : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_7BjJeRi9X/.well-known/jwks.json',
        jwks : {"keys":[{"alg":"RS256","e":"AQAB","kid":"+mmlRDH/mhilVDg3ZOegS5Dgu5SQKbTVuTPv+V1k2iE=","kty":"RSA","n":"vEXAfrGrgoOKT7ET-7hAGefSphOgIVosvhwJyWH9XNSZVc_tYkzXC_ZGWhFAco5TIZ8z7Ikt9KsCNqsmXuvSK_aFf7WQpfdjBqNi6rcEDRCAb16dM3F6SHgj2XGbppC3gJtc5PTLdyEIsTCdz5xVrDSHbaV2axfJs5rHeF4a4pAtuT9-dvnKzha7d5VArVd77rdqjid-xeMzlinelyt4h77KSR0AsHp9yGfzAKxl8GCI31b47amKPx5pMqQ0asieLIH6tmXfVqUU9xVdbL86gUudEV5QChMetMeMk2nd9a6hzhCfXr2_hTs-TkbDILu_Ge9DTH5C6qpaEyfx0wQW7w","use":"sig"},{"alg":"RS256","e":"AQAB","kid":"+yVFb6SG11qkvMSMSF1msXUSjLlsOu5qvwnGlveYTb4=","kty":"RSA","n":"5ZnlXqN6qA4QkjHmNWcSu8Ek8q6N3LfGQPlz9uS-aYTG95JtKOaqYqubbKbc3-yDOMC9dlk0MKmVxFomfdGDiW1k5zMJZpFsOHGtsaA0j_AmIZtA7HMi0N_ttzuqal7slPPa-IgIRdDA172gyFDtNA3ti-3klWg5uchyyFDmY4KIJjp0iN0kML9krYqmShTq5KSh-6HLcwIcUB4FAs1Di6hZgc6xjFsTTMxtTrM6SWNrOP37a34HfUyU_guGaK_5pHTegR2p0R0rm6Euw1LEaq-47mM34_GMpcbgWJ73VR5kuyQW3JEFRcYyTJ0PM9fL7Js33K0HzBv7zHfzkcP4nQ","use":"sig"}]},
        aud : '7u7o2d3rt8v0hifcrglo0muo08', // should match the app client ID that was created in the Amazon Cognito user pool
        iss : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_7BjJeRi9X',
        apiKeyPrefix: 'ApiKey',
        userPool: 'ap-southeast-2_7BjJeRi9X',
        idPool: 'ap-southeast-2:ef6e2334-7544-4738-8dea-d5af5b8586a6',
        domain: 'evrimaconnect-prod.auth.ap-southeast-2.amazoncognito.com',
        redirectSignIn: 'https://evrilink.com',
        redirectSignOut: 'https://evrilink.com',
    },
    mui: {
        xGridKey: '2455a9e75aabf82c7acde90550ee5da2Tz05Nzg5NixFPTE3NTc2MzMwNzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
    },
    permissions: {
        transparent: true // if false, a badge saying permission required will show where components are blocked by incorrect permissions
    },
    help: {
        link: 'https://evrilink.com/contact-us/?enquiry_type=Evrima%20Connect%20Support'
    },
    docApi: 'https://kzxg5dnn46.execute-api.ap-southeast-2.amazonaws.com/Prod/agreements'
};

export default config;
