/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssessImageRequest } from '../models/AssessImageRequest';
import type { AssessTaskFileStatusRequest } from '../models/AssessTaskFileStatusRequest';
import type { GetPatientFilesRequest } from '../models/GetPatientFilesRequest';
import type { GetPatientTrialImagesRequest } from '../models/GetPatientTrialImagesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MediaService {

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiMediaGetRequestFileTypeByTrialId(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Media/GetRequestFileTypeByTrialId',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMediaGetPatientRequestFilesByTrialId(
requestBody?: GetPatientFilesRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Media/GetPatientRequestFilesByTrialId',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMediaGetPatientFilesWithRequestIdByTrialId(
requestBody?: GetPatientFilesRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Media/GetPatientFilesWithRequestIdByTrialId',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMediaGetPatientFilesByTrialId(
requestBody?: GetPatientTrialImagesRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Media/GetPatientFilesByTrialId',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param trialId 
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiMediaGetSitePatientFiles(
trialId?: number,
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Media/GetSitePatientFiles',
            query: {
                'trialId': trialId,
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param imageKey 
     * @returns binary OK
     * @throws ApiError
     */
    public static getApiMediaDownloadPatientFile(
imageKey?: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Media/DownloadPatientFile',
            query: {
                'imageKey': imageKey,
            },
        });
    }

    /**
     * @param formData 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMediaUploadFile(
formData?: {
filedata?: Blob;
userCognitoSub?: string;
trialId?: string;
requestId?: string;
description?: string;
originalImageId?: number;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Media/UploadFile',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiMediaUpdatePatientRequestFileStatus(
requestBody?: AssessTaskFileStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Media/UpdatePatientRequestFileStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiMediaUpdatePatientFileStatus(
requestBody?: AssessImageRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Media/UpdatePatientFileStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiMediaDeletePatientFile(
requestBody?: AssessImageRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Media/DeletePatientFile',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
