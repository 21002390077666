import services from './../utils/mockAdapter';


// project imports
import trials from './../_mockApis/data/Trials';

import patients from './../_mockApis/data/EvrimaPatients';


import patient1 from './../_mockApis/data/PatientDashboard1';






//-----------------------|| MOCK SERVICES ||-----------------------//

services.onGet('/api/trials').reply((config) => {
    try {
        
        return [
            200,
            {trials: trials}
        ];
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});

services.onGet('/api/patients').reply((config) => {
    try {
        
        return [
            200,
            {patients: patients}
        ];
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});


services.onGet('/api/patient').reply((config) => {
    try {
        
        return [
            200,
            {data: {
                patient : {
                    id : 1,
                    email : 'myemail@site.com',
                    phoneNumber : '97770000',
                    firstName : 'Jane',
                    lastName : 'Doe',
                    dob : '1979-12-12T00:00:00',
                    sex : 1,
                    location : 'Melbourne',
                    role : 0,
                    registered : '2020-12-12T00:00:00',
                    medical_conditions : [
                        'High blood pressure',
                        'Overweight'
                    ]
                },
                trial_history : [
                    {
                        name : 'Psorias',
                        date : '2021-01-01T00:00:00',
                        status : 3
                    },
                    {
                        name : 'Toe Nail Fungus',
                        date : '2021-02-01T00:00:00',
                        status : 0
                    }
                ],
                current_trial : {
                    id : 1,
                    status : 0,
                    trial_survey : 'https://jotform.com',
                    trial_details : [
                        {
                            name : 'Trial name',
                            value : 'Toe Nail Fungus'
                        },
                        {
                            name: 'Trial length',
                            value : '31 days'
                        }
                    ]
                },
                contact_log : [
                    {
                        date_time : '2021-01-01T00:00:00',
                        outcome : 'Left message',
                        type : 'Call',
                        note : 'Lorem ipsum',
                        logged_by : 'FirstName LastName'
                    }
                ],
                notes : [
                    {
                        date_time : '2021-01-01T00:00:00',
                        logged_by : 'FirstName LastName',
                        note : 'Lorem ipsum'
                    }
                ]
            }}
        ];
    // eslint-disable-next-line no-unreachable
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});

services.onGet('/api/patient/1').reply((config) => {
    try {
        
        return [
            200,
            {results: patient1}
        ];
    } catch (err) {
        return [500, { message: 'Server Error' }];
    }
});