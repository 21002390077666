

// action - single trial
export const SINGLE_TRIAL_LOAD = '@singleTrial/SINGLE_TRIAL_LOAD';
export const SINGLE_TRIAL_LOADED = '@singleTrial/SINGLE_TRIAL_LOADED';
export const SINGLE_TRIAL_WORKFLOW_LOAD = '@singleTrial/SINGLE_TRIAL_WORKFLOW_LOAD';
export const SINGLE_TRIAL_WORKFLOW_LOADED = '@singleTrial/SINGLE_TRIAL_WORKFLOW_LOADED';
export const SINGLE_TRIAL_WORKFLOW_EVENTS_LOAD = '@singleTrial/SINGLE_TRIAL_WORKFLOW_EVENTS_LOAD';
export const SINGLE_TRIAL_WORKFLOW_EVENTS_LOADED = '@singleTrial/SINGLE_TRIAL_WORKFLOW_EVENTS_LOADED';
export const SINGLE_TRIAL_SITE_FILTER_SELECT = '@singleTrial/SINGLE_TRIAL_SITE_FILTER_SELECT';
export const SINGLE_TRIAL_TOGGLE_FAVORITE = '@singleTrial/SINGLE_TRIAL_TOGGLE_FAVORITE';
export const SINGLE_TRIAL_PREFERENCES_LOAD = '@singleTrial/SINGLE_TRIAL_PREFERENCES_LOAD';
export const SINGLE_TRIAL_PREFERENCES_SAVE = '@singleTrial/SINGLE_TRIAL_PREFERENCES_SAVE';

export const SINGLE_TRIAL_PARTICIPANTS_LOAD = '@singleTrial/SINGLE_TRIAL_PARTICIPANTS_LOAD';
export const SINGLE_TRIAL_PARTICIPANTS_LOADED = '@singleTrial/SINGLE_TRIAL_PARTICIPANTS_LOADED';
export const SINGLE_TRIAL_RESULTS_PER_PAGE_CHANGE = '@singleTrial/SINGLE_TRIAL_RESULTS_PER_PAGE_CHANGE';
export const SINGLE_TRIAL_PAGE_NUMBER_CHANGE = '@singleTrial/SINGLE_TRIAL_PAGE_NUMBER_CHANGE';
export const SINGLE_TRIAL_SORT_BY_CHANGE = '@singleTrial/SINGLE_TRIAL_SORT_BY_CHANGE';
export const SINGLE_TRIAL_ORDER_BY_CHANGE = '@singleTrial/SINGLE_TRIAL_ORDER_BY_CHANGE';
export const SINGLE_TRIAL_SEARCH_CHANGE = '@singleTrial/SINGLE_TRIAL_SEARCH_CHANGE';
export const SINGLE_TRIAL_MODE_CHANGE = '@singleTrial/SINGLE_TRIAL_MODE_CHANGE';
export const SINGLE_TRIAL_FILTER_LOAD = '@singleTrial/SINGLE_TRIAL_FILTER_LOAD';
export const SINGLE_TRIAL_FILTER_LOADED = '@singleTrial/SINGLE_TRIAL_FILTER_LOADED';

