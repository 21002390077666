import {makeStyles} from "@mui/styles";

export const useAppBarStyles = makeStyles((theme: any) => ({
    appBar: {
        boxShadow: '0 1px 8px 0 rgb(0 0 0 / .1), 0 1px 7px -1px rgb(0 0 0 / .1)',
        backgroundColor: '#fff',
        '& .is-active button': {
            //background: theme.palette.primary.light,
            color: theme.palette.primary.dark
        }
    },
    navItem: {
        //color: theme.palette.grey[900]
        color: "#000"
    },
    navLink: {
        textDecoration: "none",
        color: "#000",
        '&.is-active': {
            //background: theme.palette.primary.light,
            color: theme.palette.primary.dark
        }
    },
    toolbar: {
        padding:'.8rem 1rem .8rem 1.2rem'
    },
    logo: {
        margin: '0 1rem 0 0',
        minWidth: 0
    }
}));