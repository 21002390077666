import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {ParticipantManagementState} from "../interfaces/participant-management.state";
import { ParticipantManagementAction } from "../interfaces/participant-management.action";
import {UnknownAction} from "redux";

const initialState = {
    isLoadingParticipantImportedRecord: true,
    isLoadingCompanyParticipantImported: true,
    isLoadingTrial: true,
    companyParticipantImported: [],
    therapeuticAreas: [],
    companies: [],
    userTrials: [],
    participantImportedRecord: [],
    duplicateParticipantImportedRecord: [],
    duplicateParticipantImportedRecordByFilter: [],
    totalParticipantImportedRecord: 0
};

const participantManagementReducer = (state: ParticipantManagementState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case actionTypes.COMPANY_PARTICIPANT_IMPORTED_LOAD:
            return produce(state, draft => {
                draft.isLoadingCompanyParticipantImported = true;
        });
        case actionTypes.COMPANY_PARTICIPANT_IMPORTED_LOADED:
            return produce(state, draft => {
                draft.isLoadingCompanyParticipantImported = false;
                draft.companyParticipantImported = (action as any).companyParticipantImported;
        });
        case actionTypes.THERAPEUTICAREA_LOADED:
            return produce(state, draft => {
                draft.therapeuticAreas = (action as any).therapeuticAreas;
        });
        case actionTypes.PARTICIPANT_IMPORTED_RECORD_LOAD:
            return produce(state, draft => {
                draft.isLoadingParticipantImportedRecord = true;
        });
        case actionTypes.PARTICIPANT_IMPORTED_RECORD_LOADED:
            return produce(state, draft => {
                draft.isLoadingParticipantImportedRecord = false;
                draft.participantImportedRecord = (action as any).participantImportedRecord;
                draft.duplicateParticipantImportedRecord = (action as any).duplicateParticipantImportedRecord;
                draft.totalParticipantImportedRecord = (action as any).totalParticipantImportedRecord;
                draft.duplicateParticipantImportedRecordByFilter = (action as any).duplicateParticipantImportedRecordByFilter
        });
        case actionTypes.COMPANY_PARTICIPANT_LOADED:
            return produce(state, draft => {
                draft.companies = (action as any).companies;
        });
        case actionTypes.TRIAL_USER_LOADED:
            return produce(state, draft => {
                draft.userTrials = (action as any).userTrials;
        });
        default:
            return state;
    }
};

export default participantManagementReducer;