import {UserRoleEnum} from "../../enums/user-role.enum";
import {
    COMPANY_ADMIN_PERMISSIONS_TEMPLATE,
    COMPANY_USER_PERMISSIONS_TEMPLATE,
    TRIAL_ADMIN_PERMISSIONS_TEMPLATE, TRIAL_SPONSOR_PERMISSIONS_TEMPLATE,
    TRIAL_USER_PERMISSIONS_TEMPLATE
} from "../permissions-template.constants";
import {UserPermissionsEnum} from "../enums/user-permissions.enum";
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import {UserPermissionTypesEnum} from "../enums/user-permission-types.enum";

export const getPermissionTemplate = (role: UserRoleEnum) => {
  switch(role){
      case UserRoleEnum.COMPANY_ADMIN:
          return COMPANY_ADMIN_PERMISSIONS_TEMPLATE;
      case UserRoleEnum.COMPANY_USER:
          return COMPANY_USER_PERMISSIONS_TEMPLATE;
      case UserRoleEnum.TRIAL_ADMIN:
          return TRIAL_ADMIN_PERMISSIONS_TEMPLATE;
      case UserRoleEnum.TRIAL_USER:
          return TRIAL_USER_PERMISSIONS_TEMPLATE;
      case UserRoleEnum.TRIAL_SPONSOR:
          return TRIAL_SPONSOR_PERMISSIONS_TEMPLATE;
  }
}

export const permissionsToRole = (permissions: UserPermissionsEnum[], type: UserPermissionTypesEnum): UserRoleEnum | undefined  => {
    if (type === UserPermissionTypesEnum.Company && isEqual(uniq(permissions.sort()), COMPANY_ADMIN_PERMISSIONS_TEMPLATE.sort())){
        return UserRoleEnum.COMPANY_ADMIN;
    } else if (type === UserPermissionTypesEnum.Company && isEqual(uniq(permissions.sort()), COMPANY_USER_PERMISSIONS_TEMPLATE.sort())){
        return UserRoleEnum.COMPANY_USER;
    } else if (type === UserPermissionTypesEnum.Trial && isEqual(uniq(permissions.sort()), TRIAL_ADMIN_PERMISSIONS_TEMPLATE.sort())){
        return UserRoleEnum.TRIAL_ADMIN;
    } else if (type === UserPermissionTypesEnum.Trial && isEqual(uniq(permissions.sort()), TRIAL_USER_PERMISSIONS_TEMPLATE.sort())){
        return UserRoleEnum.TRIAL_USER;
    } else if (type === UserPermissionTypesEnum.Trial && isEqual(uniq(permissions.sort()), TRIAL_SPONSOR_PERMISSIONS_TEMPLATE.sort())) {
        return UserRoleEnum.TRIAL_SPONSOR
    }
}