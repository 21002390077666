export const COMPANY_MANAGEMENT_LOAD = '@company-management/COMPANY_MANAGEMENT_LOAD';
export const COMPANY_MANAGEMENT_LOADED = '@company-management/COMPANY_MANAGEMENT_LOADED';
export const COMPANY_MANAGEMENT_SELECT_COMPANY = '@company-management/COMPANY_MANAGEMENT_SELECT_COMPANY';
export const COMPANY_MANAGEMENT_SELECTED_COMPANY = '@company-management/COMPANY_MANAGEMENT_SELECTED_COMPANY';
export const COMPANY_MANAGEMENT_UNSELECT_COMPANY = '@company-management/COMPANY_MANAGEMENT_UNSELECT_COMPANY';
export const COMPANY_MANAGEMENT_USERS_LOAD = '@company-management/COMPANY_MANAGEMENT_USERS_LOAD';
export const COMPANY_MANAGEMENT_USERS_LOADED = '@company-management/COMPANY_MANAGEMENT_USERS_LOADED';
export const COMPANY_MANAGEMENT_TRIALS_LOAD = '@company-management/COMPANY_MANAGEMENT_TRIALS_LOAD';
export const COMPANY_MANAGEMENT_TRIALS_LOADED = '@company-management/COMPANY_MANAGEMENT_TRIALS_LOADED';
export const COMPANY_MANAGEMENT_LOCATIONS_LOAD = '@company-management/COMPANY_MANAGEMENT_LOCATIONS_LOAD';
export const COMPANY_MANAGEMENT_LOCATIONS_LOADED = '@company-management/COMPANY_MANAGEMENT_LOCATIONS_LOADED';
export const COMPANY_MANAGEMENT_LOCATIONS_SAVE = '@company-management/COMPANY_MANAGEMENT_LOCATIONS_SAVE';
export const COMPANY_MANAGEMENT_LOCATIONS_SAVED = '@company-management/COMPANY_MANAGEMENT_LOCATIONS_SAVED';
export const COMPANY_MANAGEMENT_SAVE_INVITED_USERS = '@company-management/COMPANY_MANAGEMENT_SAVE_INVITED_USERS';
export const COMPANY_MANAGEMENT_INVITED_USERS_SAVED = '@company-management/COMPANY_MANAGEMENT_INVITED_USERS_SAVED';
export const COMPANY_MANAGEMENT_ROLE_CHANGE = '@company-management/COMPANY_MANAGEMENT_ROLE_CHANGE';
export const COMPANY_MANAGEMENT_ROLE_CHANGED = '@company-management/COMPANY_MANAGEMENT_ROLE_CHANGED';
export const COMPANY_MANAGEMENT_USER_REMOVE = '@company-management/COMPANY_MANAGEMENT_USER_REMOVE';
export const COMPANY_MANAGEMENT_USER_REMOVED = '@company-management/COMPANY_MANAGEMENT_USER_REMOVED';