import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {applyMiddleware, compose, createStore} from 'redux';
import { Provider } from 'react-redux';
import './_mockApis';
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import './assets/scss/style.scss';
import {createRoot} from "react-dom/client";
import {store} from "./store/store";



const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <BrowserRouter basename={config.basename}>
         <App />
    </BrowserRouter>
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
