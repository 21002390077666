import {ParticipantStatusEnum} from "../../../../shared/enums/participant-status.enum";


export class ParticipantAssociatedTrialModel {
    constructor(public id: number | undefined,
                public name: string | null | undefined,
                public trialIdentifier: string | null | undefined,
                public status: ParticipantStatusEnum,
                public stageLabel: string | null | undefined,
                public isWithdrawing: boolean){}
}