import {
  PREFERRED_TIMES_LOAD,
  PREFERRED_TIMES_LOADED,
  PREFERRED_TIMES_SAVE,
  PREFERRED_TIMES_SAVED,
} from '../../../../store/actions';
import produce from 'immer';
import {initialTimes} from "../preferred-time.constants";
import {UsersService} from "../../../../api/connect";

const initialState = {
  isLoadingPreferredTimes: true,
  isSavingPreferredTimes: false,
  preferredTimes: null,
};

const preferredTimesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PREFERRED_TIMES_LOAD:
      return produce(state, (draft) => {
        draft.isLoadingPreferredTimes = true;
      });    
    case PREFERRED_TIMES_LOADED:
      return produce(state, (draft) => {
        draft.isLoadingPreferredTimes = false;
        draft.preferredTimes = action.preferredTimes;
      });
    case PREFERRED_TIMES_SAVE:
      return produce(state, (draft) => {
        draft.isSavingPreferredTimes = true;
      });
    case PREFERRED_TIMES_SAVED:
      return produce(state, (draft) => {
        draft.isSavingPreferredTimes = false;
        draft.preferredTimes = action.preferredTimes;
      });
    default:
      return state;
  }
};

export default preferredTimesReducer;

export function loadPreferredTimes(userCognitoSub) {
  return async (dispatch) => {
    dispatch({ type: PREFERRED_TIMES_LOAD });
    return UsersService.getApiUsersGetPreferredTimesPreference(userCognitoSub)
    .then((data) => {
      const preferredTimes = (data?.results) ? data.results : initialTimes;
      dispatch({ type: PREFERRED_TIMES_LOADED, preferredTimes })
    })
    .catch((error) => console.error(error));
  };
}

export function savePreferredTimes(preferredTimes, userCognitoSub) {
  return async (dispatch) => {
    dispatch({ type: PREFERRED_TIMES_SAVE });
    return UsersService.postApiUsersSavePreferredTimesPreference({
        preferredTimes,
        userCognitoSub,
      })
      .then(() => dispatch({ type: PREFERRED_TIMES_SAVED, preferredTimes }))
      .catch((error) => console.error(error));
  };
}


export function clearPreferredTimes(userId) {
  return async (dispatch, getState) => {
    return  UsersService.deleteApiUsersClearPreferredTimesPreference()
        .then(result => dispatch(loadPreferredTimes(userId)))
        .catch(error => console.error(error));
  }
}
