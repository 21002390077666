import React, {useEffect} from 'react';
// import {  useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import {Box, CssBaseline} from "@mui/material";
import clsx from 'clsx';
import AppBar from "../../shared/components/app-bar/app-bar.component";
import {Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";


// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: '3rem'
    },
    content: {
        ...theme.typography.mainContent,

    }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = () => {


    const {user} = useAuth();
    const [isNotificationBarShowing, setIsNotificationBarShowing] = React.useState(false);
    const [showContent, setShowContent] = React.useState(false);
    const classes = useStyles();
    // const theme = useTheme();

    const onShow = (show) => {
        setIsNotificationBarShowing(show);
    }

    useEffect(() => {
        setShowContent(!!user)
    },[user]);


    return (
        <div className={classes.root}>
           <>

                <CssBaseline />
                {/* header */}
                { showContent &&  <AppBar onNotificationShow={onShow} /> }

                {/* main content */}

                <Box component={"main"}
                     sx={{ marginTop: (isNotificationBarShowing) ? '116px !important' : 'initial' }}
                    className={clsx([
                        classes.content
                ])}
                >
                     <div><Outlet /></div>
                </Box>

            </>
        </div>
    );
};

export default MainLayout;
