import {applyMiddleware, legacy_createStore as createStore} from "redux";
import {thunk} from "redux-thunk";
import reducers from "./reducers";
import {composeWithDevTools} from "@redux-devtools/extension";


export const store = createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(thunk)
    ),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
