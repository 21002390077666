import {AgreementsProps} from "./agreements.props";
import useAuth from "../../../hooks/useAuth";
import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {Button, CardActions, CardContent, Dialog, Tabs, Typography, useMediaQuery} from "@mui/material";
import {AgreementTypes, AgreementUserTypes, UsersService} from "../../../api/connect";
import {AgreementsEnum} from "./agreements.enum";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, Link, Stack, Tab} from "@mui/material";
import {RoleEnum} from "../../enums/role.enum";
import config from "../../../config";
import {AgreementsContext} from "../../../contexts/agreements.context";
import {useTheme} from "@mui/styles";
import {useAgreementsStyles} from "./agreements.styles";
import {RecordStatusesEnum} from "../../enums/record-statuses.enum";
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import {Close} from "@mui/icons-material";
import {hasValidProfile} from "../../utils/has-valid-profile";


const AgreementsDialog = (props: AgreementsProps) => {
  
    const [isSaving, setIsSaving] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [privacyChecked, setPrivacyChecked] =  useState(false);
    const [ privacyVersion, setPrivacyVersion ] = useState(-1);
    const [ privacyContent, setPrivacyContent ] = useState();
    const [termsChecked, setTermsChecked] =  useState(false);
    const [ termsVersion, setTermsVersion ] = useState(-1);
    const [ termsContent, setTermsContent ] = useState();
    const { user, logout } = useAuth() as any;
    const { isDialogOpen, showDialog, hideDialog, isReadOnly, activeTab, showTab } = useContext(AgreementsContext);
    const theme = useTheme() as any;
    const classes = useAgreementsStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const onAccept = async() => {
        try {
            if (privacyChecked && termsChecked) {
                setIsSaving(true);
                if (user && user.role === RoleEnum.PARTICIPANT) {
                    await UsersService.putApiUsersUpdateUser({
                        authorCognitoSub: user.cognitoSub,
                        userData: user,
                        privacyPolicyAgreement: { updatedVersion: privacyVersion },
                        termsOfServiceAgreement: { updatedVersion: termsVersion },
                    });
                } else {
                    await UsersService.putApiUsersUpdateNonPatientUser({
                        authorCognitoSub: user.cognitoSub,
                        userCognitoSub: user.cognitoSub,
                        privacyPolicyAgreement: { updatedVersion: privacyVersion },
                        termsOfServiceAgreement: { updatedVersion: termsVersion },
                    });
                }
                setIsSaving(false);
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onDecline = async () => {
        await logout();
        hideDialog();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        showTab(newValue as unknown as AgreementsEnum);
    };

    const onActivateTab = (tab: AgreementsEnum) => {
        showTab(tab);
    }

    const handlePrivacyChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
        setPrivacyChecked(checked);
    };

    const handleTermsChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
        setTermsChecked(checked);
    };

    useEffect(() => {
        console.log(activeTab);
    },[activeTab])

    useEffect(() => {
        setDisabled((!privacyChecked || !termsChecked || privacyVersion === -1 || termsVersion === -1));
    },[privacyChecked, privacyVersion, termsChecked, termsVersion]);

    useEffect(() => {

        if(!user || !user?.role || user?.recordStatus === RecordStatusesEnum.Unverified || !hasValidProfile(user.locale, user.phoneNumber)){
            return;
        }

        if(!user.termsOfServiceAgreement || !user.privacyPolicyAgreement){
            showDialog();
            return;
        }

        const userType =  (user && user.role && user.role !== RoleEnum.PARTICIPANT) ? AgreementUserTypes._1 : AgreementUserTypes._0;

        UsersService.getApiUsersGetUserAgreementDocument(AgreementsEnum.PrivacyPolicy as any as AgreementTypes, userType)
            .then(policy => {
                UsersService.getApiUsersGetUserAgreementDocument(AgreementsEnum.TermsOfService as any as AgreementTypes, userType)
                    .then(terms => {
                        if ((policy?.results?.versionNumber && user.privacyPolicyAgreement.updatedVersion < policy?.results?.versionNumber) ||
                            (terms?.results?.versionNumber && user.termsOfServiceAgreement.updatedVersion < terms?.results?.versionNumber)) {
                            showDialog();
                        }
                    }).catch(err => console.error(err));
            }).catch(err => console.error(err));

    },[showDialog, user]);


    useEffect(() => {
        if(isDialogOpen) {
            let userType = '&userType=0';
            if (user && user.role && user.role !== RoleEnum.PARTICIPANT) {
              userType =  '&userType=1';
            }
            const loadPrivacy = async () => {
                const result = await fetch(`${config.docApi}?type=${AgreementsEnum.PrivacyPolicy}&${userType}`);
                const text = await result.text();
                const json = JSON.parse(text);
                setPrivacyContent(json?.results?.documentContent);
                setPrivacyVersion(json?.results?.versionNumber);
            };
            const loadTerms = async () => {
                const result = await fetch(`${config.docApi}?type=${AgreementsEnum.TermsOfService}&${userType}`);
                const text = await result.text();
                const json = JSON.parse(text);
                setTermsContent(json?.results?.documentContent);
                setTermsVersion(json?.results?.versionNumber);
            };
            void loadPrivacy();
            void loadTerms();
        }
    }, [isDialogOpen, user]);

    return (<div>
        <Dialog fullScreen={fullScreen}  open={isDialogOpen}>
            <TabContext value={activeTab}>
                <CardContent>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                        <Box>
                            <Typography variant='h1'>Agreements</Typography>
                            <Typography variant='h4'>Evrima Technologies Pty Ltd</Typography>
                        </Box>
                        { isReadOnly && <MobileView>
                            <IconButton aria-label="close" onClick={() => hideDialog()} color="primary" size={"large"}>
                                <Close />
                            </IconButton>
                            </MobileView>}
                    </Stack>
                </CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab}
                          onChange={handleChange}
                          aria-label="Agreement navigation"
                          variant="scrollable">
                        <Tab label="Privacy policy" value={String(AgreementsEnum.PrivacyPolicy as unknown as string)} />
                        <Tab label="Terms of service" value={String(AgreementsEnum.TermsOfService as unknown as string)} />
                        <Tab label="Privacy Collection Notice" value='notice' />
                    </Tabs>
                </Box>
                <CardContent sx={{ overflowY: 'auto', padding: '0', height: '100%'}}>
                        <TabPanel sx={{ height: '100%'}} value={String(AgreementsEnum.PrivacyPolicy as unknown as string)}>
                          <div className={classes.documents} dangerouslySetInnerHTML={{ __html: privacyContent || '' }} />
                        </TabPanel>
                        <TabPanel sx={{ height: '100%'}} value={String(AgreementsEnum.TermsOfService as unknown as string)}>
                          <div className={classes.documents}  dangerouslySetInnerHTML={{ __html: termsContent || '' }} />
                        </TabPanel>
                        <TabPanel sx={{ height: '100%'}} value='notice'>
                          <div className={classes.documents}>
                            <h1>Evrima Technologies Pty Limited – Privacy Collection Notice</h1>
                            <p>This Privacy Collection Notice takes into account the requirements of the Australian Privacy Act 1988 (Cth), the Australian Privacy Principles, the New Zealand Privacy Act 2020 and the New Zealand Health Information Privacy Code 2020. </p>
                            <p>This Privacy Collection Notice describes how Evrima Technologies Pty Limited ABN 78 631 919 698 (we, us or our) collect and handle your personal information when you sign up for our patient recruitment services for clinical trials through our platform Evrima Connect. We collect personal information from you and/or from third parties, including third party service providers, such as our cookie and analytics providers, so that we can provide our patient recruitment services and for related purposes set out in our Privacy Policy, available at www.evrima.com. </p>
                            <p>We may disclose this personal information to third parties, including our Clients (as defined in the Privacy Policy), third party research organisations, our third party services providers, our employees, related entities, sponsors or organisations responsible for the clinical trials, any third parties engaged by us and acting on our behalf and as otherwise set out in our Privacy Policy (linked above). </p>
                            <p>While we store personal information in Australia and New Zealand, where we disclose your personal information to the third parties listed above, these third parties may store, transfer or access personal information outside of Australia and New Zealand, including but not limited to, United States.</p>
                            <p>If you do not provide your personal information to us, it may affect our ability to provide our Services to you and your use of our Services. If you do not agree with this Privacy Collection Notice, please discontinue the use of our Services. Your use of the Services will signify your assent to and acceptance of this Privacy Collection Notice.</p>
                            <p>Please see our Privacy Policy (linked above) for more information about how we collect, store, use, and disclose your personal information, including details about overseas disclosure, access, correction, how you can make a privacy-related complaint, and our complaint-handling process. </p>
                            <p>If you have questions about our privacy practices, please contact us by email at:</p>
                            <p><b>For Australia</b></p>
                            <p>Email: <a href='mailto:enquiries@evrima.com.au'>enquiries@evrima.com.au</a></p>
                            <p>By providing your personal information to us, you agree to the collection, use, storage, and disclosure of that information as described in this privacy collection notice.</p>
                          </div>
                        </TabPanel>
                </CardContent>
            </TabContext>
            <Divider />
            { !isReadOnly && <React.Fragment>

                <CardActions sx={{paddingBottom: 0}}>
                    <FormGroup>
                        <FormControlLabel checked={privacyChecked} onChange={handlePrivacyChange} control={<Checkbox/>}
                                          label={<label>I agree and accept the <Link component={"button"}
                                                                                     variant={"body2"} onClick={() => {
                                              onActivateTab(AgreementsEnum.PrivacyPolicy);
                                          }}>Privacy policy</Link></label>}/>
                        <FormControlLabel checked={termsChecked} onChange={handleTermsChange} control={<Checkbox/>}
                                          label={<label>I agree and accept the <Link component={"button"}
                                                                                     variant={"body2"} onClick={() => {
                                              onActivateTab(AgreementsEnum.TermsOfService);
                                          }}>Terms of service</Link></label>}/>
                    </FormGroup>
                </CardActions>

                <CardActions sx={{display: 'flex', justifyContent: isMobile ? 'flex-start' : 'space-between'}}>
                    <Button color='primary' variant='outlined' onClick={onDecline}>
                        Decline and logout
                    </Button>
                    <LoadingButton loading={isSaving} color='primary' variant='contained' onClick={onAccept}
                                   disabled={disabled}>
                        Accept
                    </LoadingButton>
                </CardActions>

            </React.Fragment>
            }

            { isReadOnly &&
                <BrowserView>
                    <CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button color='primary' variant='contained' onClick={() => hideDialog()}>
                            Ok
                        </Button>
                    </CardActions>
                </BrowserView>
            }

        </Dialog>
    </div>);
}

export default AgreementsDialog;