import produce from 'immer';
import {
  TRIAL_ATTACHMENTS_DELETE,
  TRIAL_ATTACHMENTS_DELETED,
  TRIAL_ATTACHMENTS_LOAD,
  TRIAL_ATTACHMENTS_LOADED,
  TRIAL_ATTACHMENTS_UPLOAD,
  TRIAL_ATTACHMENTS_UPLOAD_DIALOG_CLOSED,
  TRIAL_ATTACHMENTS_UPLOAD_FAILED,
  TRIAL_ATTACHMENTS_UPLOADED,
  TRIAL_FILE_REQUEST_LOAD,
  TRIAL_FILE_REQUEST_LOADED,
  TRIAL_FILE_REQUESTS_LOAD,
  TRIAL_FILE_REQUESTS_LOADED,
  TRIAL_TAB_CHANGE,
  TRIAL_WITHDRAW,
  TRIAL_WITHDRAWN,
  TRIAL_DETAIL_LOAD,
  TRIAL_DETAIL_LOADED
} from './trial-detail.actions';
import {TrialDetailTypeEnum} from "../enums/trial-detail-type.enum";
import {TrialDetailAction} from "../interfaces/trial-detail.action";
import {TrialDetailState} from "../interfaces/trial-detail.state";
import {TrialAttachmentsListModel} from "../models/trial-attachments-list.model";
import moment from "moment";
import {TrialRequestsListModel} from '../models/trial-request-list.model';
import {FileRequestStatusEnum} from "../../../../shared/enums/file-request-status.enum";
import {FileModel} from "../../../file-library/models/file.model";
import {RequestModel} from "../../../file-library/models/request.model";
import {FileStatusEnum} from "../../../../shared/enums/file-status.enum";
import {UnknownAction} from "redux";

const initialState = {
  isWithdrawing: false,
  activeTab: TrialDetailTypeEnum.TRIAL_STATUS,
  isLoadingAttachments: false,
  attachments: [],
  isUploading: false,
  closeDialog: false,
  uploadError: false,
  isLoadingRequests: false,
  requests: [],
  requestNeedsAttention: 0,
  isLoadingRequest: false,
  selectedRequest: null,
  trial: null,
  isLoadingTrial: false,
};

const trialDetailReducer = (
  state: TrialDetailState = initialState,
  action: UnknownAction
) => {
  switch ((action as any).type) {
    case TRIAL_WITHDRAW:
      return produce(state, (draft) => {
        draft.isWithdrawing = true;
      });
    case TRIAL_WITHDRAWN:
      return produce(state, (draft) => {
        draft.isWithdrawing = false;
      });
    case TRIAL_TAB_CHANGE:
      return produce(state, (draft) => {
        draft.activeTab = (action as any).tab;
      });
    case TRIAL_ATTACHMENTS_LOAD:
      return produce(state, (draft) => {
        draft.isLoadingAttachments = true;
      });
    case TRIAL_ATTACHMENTS_LOADED:
      return produce(state, (draft) => {
        draft.attachments = (action as any).attachments.filter((a:any) => !a.requestId)
            .map((a:any) => new TrialAttachmentsListModel(
                a.id,
                a.imageKey,
                a.description,
                moment.utc(a.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                a.patientCognitoSub,
                a.imageStatuses));
        draft.isLoadingAttachments = false;
      });
    case TRIAL_ATTACHMENTS_UPLOAD:
      return produce(state, (draft) => {
        draft.isUploading = true;
        draft.uploadError = false;
      });
    case TRIAL_ATTACHMENTS_UPLOADED:
      return produce(state, (draft) => {
        draft.isUploading = false;
        draft.closeDialog = true;
        draft.uploadError = false;
      });
    case TRIAL_ATTACHMENTS_UPLOAD_DIALOG_CLOSED:
      return produce(state, (draft) => {
        draft.closeDialog = false;
        draft.uploadError = false;
      });
    case TRIAL_ATTACHMENTS_UPLOAD_FAILED:
      return produce(state, (draft) => {
        draft.isUploading = false;
        draft.uploadError = true;
      });
    case TRIAL_ATTACHMENTS_DELETE:
      return produce(state, (draft) => {
        const file = draft.attachments.find((f) => Number(f.id) === Number((action as any).isDeleting));
        if (file){
          file.isDeleting = true;
        }
      });
    case TRIAL_ATTACHMENTS_DELETED:
      return produce(state, (draft) => {
        draft.attachments = [...draft.attachments].filter((f) => Number(f.id) !== Number((action as any).isDeleting));

        if(draft.selectedRequest !== null) {
          const request = {...draft.selectedRequest};
          request.files = [...request.files].filter(f => Number(f.id) !== Number((action as any).isDeleting));
          draft.selectedRequest = request;
        }
      });

      case TRIAL_FILE_REQUESTS_LOAD:
        return produce(state, (draft) => {
          draft.isLoadingRequests = true;
        });
      case TRIAL_FILE_REQUESTS_LOADED:
        return produce(state, (draft) => {
          const requests = (action as any).requests
              .map((a:any) => {
                return new TrialRequestsListModel(
                  a.requestId,
                  a.taskId,
                  a.description,
                  a.authorCognitoSub,
                  a.authorFirstName,
                  a.authorLastName,
                  a.sampleKey,
                  a.sampleImage,
                  a.instructions,
                    a.requestFileStatus.length ? a.requestFileStatus[0].patientCognitoSub : '',
                    a.requestFileStatus.length ? a.requestFileStatus[0].patientFirstName : '',
                    a.requestFileStatus.length ? a.requestFileStatus[0].patientLastName : '',
                    a.requestFileStatus,
                    a.trialImages ? a.trialImages
                        .map((file: any) => new FileModel(file.id,
                            file.imageKey,
                            file.description,
                            moment.utc(file.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                            a.requestId,
                            a.patientCognitoSub,
                            file.imageStatuses)
                        ) : []
                )});


          // change request status based on file statuses
          requests.forEach((r: RequestModel) => {
            if (!!r.files.find(f => f.status === FileStatusEnum.ReRequest)){
              r.status = FileRequestStatusEnum.ReRequest;
            } else if(r.status !== undefined){
              r.status = FileRequestStatusEnum.AwaitingAssessment;
            }
          });

          if(draft.selectedRequest !== null) {
            const r = requests.find((r:any) => r.id === draft.selectedRequest?.id)
            if (r) {
              const newRequest = new RequestModel(
                  draft.selectedRequest.id,
                  draft.selectedRequest.name,
                  draft.selectedRequest.participantCognito,
                  draft.selectedRequest.participantFirstName,
                  draft.selectedRequest.participantLastName,
                  r.statuses,
                  r.files
              );
              newRequest.status = r.status;
              draft.selectedRequest = newRequest;
            }
          }

          const needsAttention = requests.filter((r: TrialRequestsListModel) => r.status === FileRequestStatusEnum.ReRequest || r.status === undefined);
          draft.requestNeedsAttention = needsAttention.length;
          draft.requests = requests;
          draft.isLoadingRequests = false;
        });

    case TRIAL_FILE_REQUEST_LOAD:
      return produce(state, (draft) => {
        draft.isLoadingRequest = true;
      });
    case TRIAL_FILE_REQUEST_LOADED:
      return produce(state, (draft) => {
        const request = (action as any).request;
        if (request) {
          const selectedRequest = new RequestModel(
              request.requestId,
              request.description,
              request.requestFileStatus.length ? request.requestFileStatus[0].patientCognitoSub : '',
              request.requestFileStatus.length ? request.requestFileStatus[0].patientFirstName : '',
              request.requestFileStatus.length ? request.requestFileStatus[0].patientLastName : '',
              request.requestFileStatus,
              request.trialImages ? request.trialImages
                  .map((file:any) => new FileModel(file.id,
                      file.imageKey,
                      file.description,
                      moment.utc(file.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                      request.requestId,
                      request.patientCognitoSub,
                      file.imageStatuses)
                  ) : []
          );

          if(selectedRequest.status !== undefined){
            selectedRequest.status = FileRequestStatusEnum.AwaitingAssessment;
          }
          draft.selectedRequest = selectedRequest;
        }
        draft.isLoadingRequest = false;
    });
    case TRIAL_DETAIL_LOAD:
        return produce(state, draft => {
            draft.isLoadingTrial = true;
    });
    case TRIAL_DETAIL_LOADED:
        return produce(state, draft => {
            draft.isLoadingTrial = false;
            draft.trial = (action as any).trial;
    });
    default:
      return state;
  }
};

export default trialDetailReducer;


