import * as actionTypes from './audit-history.actions';
import produce from 'immer';
import { AuditHistoryState } from '../interfaces/audit-history.state';
import { AuditHistoryAction } from '../interfaces/audit-history.action';
import {UnknownAction} from "redux";

const initialState: AuditHistoryState = {
  isLoading: false,
  entries: [],
  total: 0,
};

const auditHistoryReducer = (
  state: AuditHistoryState = initialState,
  action: UnknownAction
): AuditHistoryState => {
  switch ((action as any).type) {
    case actionTypes.AUDIT_HISTORY_LOAD:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.entries = [];
        draft.total = 0;        
      });
    case actionTypes.AUDIT_HISTORY_LOADED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.entries = (action as any).entries ? (action as any).entries : [];
        draft.total = (action as any).total;        
      });
    default:
      return state;
  }
};

export default auditHistoryReducer;
