// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SEND_VERIFY, SEND_RESET, DO_RESET, FAIL_RESET, CLEAR_RESET, CLEAR_VERIFY, OTC_SENT } from './actions';

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, userId, cognitoSub, isRegistered , user} = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                userId, 
                cognitoSub, 
                isRegistered,
                user
            };
        }
        case OTC_SENT: {
            const { user } = action.payload;
            return {
                ...state,
                user
            }
        }
        case LOGIN: {
            const { userId, cognitoSub, isRegistered, user, isLoggedIn } = action.payload;
            return {
                ...state,
                isLoggedIn,
                userId, 
                cognitoSub, 
                isRegistered,
                user
            };
        }
        case LOGOUT: {
            const { isLoggedIn } = action.payload;
            return {
                ...state,
                isLoggedIn,
                userId : null,
                cognitoSub : null,
                isRegistered : false,
                user : null
            };
        }
        case SEND_VERIFY: {
            return {
                ...state,
                sentVerify : true
            };
        }
        case CLEAR_VERIFY: {
            return {
                ...state,
                sentVerify : false
            };
        }
        case SEND_RESET: {
            return {
                ...state,
                sentReset : true,
                didReset : false
            };
        }
        case DO_RESET: {
            return {
                ...state,
                didReset : true
            };
        }
        case CLEAR_RESET: {
            return {
                ...state,
                sentReset : false,
                didReset : false
            };
        }
        case FAIL_RESET: {
            return {
                ...state,
                failedReset : true,
                didReset : false
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
