import {VALID_REDIRECT_PATHS} from "../shared/constants";

export const isPathValid = (pathname: string) => {

    return VALID_REDIRECT_PATHS.some(validPath => {
        if (validPath.includes(':')) {
            // Handle dynamic path - e.g., '/reports/:id'
            const basePath = validPath.split('/:')[0]; // Extract the base path (e.g., 'reports')
            return pathname.startsWith(basePath);
        } else {
            // Handle static path
            return pathname === validPath;
        }
    });
};