import {Auth, Amplify, Hub} from 'aws-amplify';
import { verifyToken } from '../../../contexts/AuthContext';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import config from '../../../config';

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    console.log(data);
    localStorage.setItem('signIn_failure', 'true');
  }
});

Amplify.configure({
  aws_project_region: 'ap-southeast-2',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: config.awsAuth.userPool, // 'ap-southeast-2_Z3B83Gjge',
  aws_user_pools_web_client_id: config.awsAuth.aud, // '44k8h29dk575da6qbt3p0iv4u7',
  aws_cognito_identity_pool_id: config.awsAuth.idPool, // 'ap-southeast-2:7dd386a0-abfa-4be4-89ba-8087d96b6c25',
  oauth: {
    domain: config.awsAuth.domain, // 'evrimaconnect-dev.auth.ap-southeast-2.amazoncognito.com',
    // scope: ['email', 'openid', 'phone', 'profile'],
    scope: ['openid'],
    redirectSignIn: config.awsAuth.redirectSignIn, // 'http://localhost:3000',
    redirectSignOut: config.awsAuth.redirectSignOut, //'https://connectdev.evrima.com.au',
    responseType: 'code',
    options: {
      AdvancedSecurityDataCollectionFlag: true,
    },
  },
});

export const checkLogin = async () => {
  try {
    if (localStorage.getItem('idToken')) {
      return;
    }
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      return;
    }
    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    const refreshToken = session.getRefreshToken().getToken();
    if (verifyToken(idToken)) {
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('accessToken', accessToken);
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
      window.location.reload();
    } else {
      console.log('Invalid amplify tokens');
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const signIn = async () => {
  try {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  } catch (error) {
    console.log('Error signing in with Google:', error);
  }
};

export const signOut = async () => {
  try {
    Auth.signOut({ global: true });
  } catch (error) {
    console.log('Error signing out with cognito:', error);
  }
};
