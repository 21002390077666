/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAutoRegisterTrialRequest } from '../models/AddAutoRegisterTrialRequest';
import type { AddUserFavouriteRequest } from '../models/AddUserFavouriteRequest';
import type { AddUserImportedRecordRequest } from '../models/AddUserImportedRecordRequest';
import type { AgreementTypes } from '../models/AgreementTypes';
import type { AgreementUserTypes } from '../models/AgreementUserTypes';
import type { BulkDeleteUserImportedRecordRequest } from '../models/BulkDeleteUserImportedRecordRequest';
import type { CreateUserAndQualifyUserAnswersRequest } from '../models/CreateUserAndQualifyUserAnswersRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { GetUserImportedRecordRequest } from '../models/GetUserImportedRecordRequest';
import type { InviteParticipantToTrialRequest } from '../models/InviteParticipantToTrialRequest';
import type { ResetUserPasswordRequest } from '../models/ResetUserPasswordRequest';
import type { SaveNotificationSettingsRequest } from '../models/SaveNotificationSettingsRequest';
import type { SavePreferredTimesPreferenceRequest } from '../models/SavePreferredTimesPreferenceRequest';
import type { UpdateNonPatientUserRequest } from '../models/UpdateNonPatientUserRequest';
import type { UpdateSiteUserSitesRequest } from '../models/UpdateSiteUserSitesRequest';
import type { UpdateUserImportedRecordRequest } from '../models/UpdateUserImportedRecordRequest';
import type { UpdateUserPermissionsRequest } from '../models/UpdateUserPermissionsRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserPermissions } from '../models/UserPermissions';
import type { UserPermissionTypes } from '../models/UserPermissionTypes';
import type { UserTypes } from '../models/UserTypes';
import type { VerifyPatientEmailRequest } from '../models/VerifyPatientEmailRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param userType 
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUsers(
userType?: UserTypes,
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUsers',
            query: {
                'userType': userType,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @param email 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUserByEmail(
email?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUserByEmail',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUserBySub(
userCognitoSub: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUserBySub',
            query: {
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetAllUserTherapeuticAreas(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetAllUserTherapeuticAreas',
        });
    }

    /**
     * @param searchTerm 
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersSearchNonPatientUsers(
searchTerm?: string,
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/SearchNonPatientUsers',
            query: {
                'searchTerm': searchTerm,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPatientProfileData(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPatientProfileData',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPatientDashboardData(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPatientDashboardData',
        });
    }

    /**
     * @param siteId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetSiteUsers(
siteId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetSiteUsers',
            query: {
                'siteId': siteId,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPatientBySub(
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPatientBySub',
            query: {
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPatientLocations(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPatientLocations',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetNotificationSettings(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetNotificationSettings',
        });
    }

    /**
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPreferredTimesPreference(
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPreferredTimesPreference',
            query: {
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUserPermissions(
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUserPermissions',
            query: {
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param permissionType 
     * @param resourceId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUserPermissionsByResource(
permissionType?: UserPermissionTypes,
resourceId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUserPermissionsByResource',
            query: {
                'permissionType': permissionType,
                'resourceId': resourceId,
            },
        });
    }

    /**
     * @param trialId 
     * @param userCognitoSub 
     * @param search 
     * @param userPermissions 
     * @param take 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetTagUsersByTrialAndUser(
trialId?: number,
userCognitoSub?: string,
search?: string,
userPermissions?: UserPermissions,
take: number = 10,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetTagUsersByTrialAndUser',
            query: {
                'trialId': trialId,
                'userCognitoSub': userCognitoSub,
                'search': search,
                'userPermissions': userPermissions,
                'take': take,
            },
        });
    }

    /**
     * @param userCognitoSub 
     * @param search 
     * @param take 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetCommentAuthorsByTaggedUser(
userCognitoSub?: string,
search?: string,
take: number = 10,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetCommentAuthorsByTaggedUser',
            query: {
                'userCognitoSub': userCognitoSub,
                'search': search,
                'take': take,
            },
        });
    }

    /**
     * @param resultsPerPage 
     * @param pageNumber 
     * @param search 
     * @param orderBy 
     * @param sortBy 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetPatientUsers(
resultsPerPage?: number,
pageNumber?: number,
search: string = '',
orderBy: string = '',
sortBy: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetPatientUsers',
            query: {
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
                'search': search,
                'orderBy': orderBy,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * @param resultsPerPage 
     * @param pageNumber 
     * @param search 
     * @param orderBy 
     * @param sortBy 
     * @param companyId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetNonPatientUsers(
resultsPerPage?: number,
pageNumber?: number,
search: string = '',
orderBy: string = '',
sortBy: string = '',
companyId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetNonPatientUsers',
            query: {
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
                'search': search,
                'orderBy': orderBy,
                'sortBy': sortBy,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param companyId 
     * @param authorCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetCompanyParticipantImportedByCompanyId(
companyId?: number,
authorCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetCompanyParticipantImportedByCompanyId',
            query: {
                'companyId': companyId,
                'authorCognitoSub': authorCognitoSub,
            },
        });
    }

    /**
     * @param fileKey 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersDownloadCompanyParticipantFile(
fileKey: string = 'sample.csv',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/DownloadCompanyParticipantFile',
            query: {
                'fileKey': fileKey,
            },
        });
    }

    /**
     * @param type 
     * @param userType 
     * @param versionNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetUserAgreementDocument(
type?: AgreementTypes,
userType?: AgreementUserTypes,
versionNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetUserAgreementDocument',
            query: {
                'type': type,
                'userType': userType,
                'versionNumber': versionNumber,
            },
        });
    }

    /**
     * @param createHsAccount 
     * @param createFacebookAccount 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersCreateUser(
createHsAccount: boolean = true,
createFacebookAccount: boolean = true,
requestBody?: CreateUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/CreateUser',
            query: {
                'createHsAccount': createHsAccount,
                'createFacebookAccount': createFacebookAccount,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param createHsAccount 
     * @param createFacebookAccount 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersAdminCreateUser(
createHsAccount: boolean = false,
createFacebookAccount: boolean = false,
requestBody?: CreateUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/AdminCreateUser',
            query: {
                'createHsAccount': createHsAccount,
                'createFacebookAccount': createFacebookAccount,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersAddFavourite(
requestBody?: AddUserFavouriteRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/AddFavourite',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersSaveNotificationSettings(
requestBody?: SaveNotificationSettingsRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/SaveNotificationSettings',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersSavePreferredTimesPreference(
requestBody?: SavePreferredTimesPreferenceRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/SavePreferredTimesPreference',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersGetParticipantImportedRecord(
requestBody?: GetUserImportedRecordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/GetParticipantImportedRecord',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param companyId 
     * @param authorCognitoSub 
     * @param formData 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersCompanyParticipantImportByCsv(
companyId?: number,
authorCognitoSub?: string,
formData?: {
filedata?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/CompanyParticipantImportByCSV',
            query: {
                'companyId': companyId,
                'authorCognitoSub': authorCognitoSub,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param fileKey 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersExecuteImportUsersByCsv(
fileKey?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/ExecuteImportUsersByCSV',
            query: {
                'fileKey': fileKey,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersAddImportUsersByCompanyId(
requestBody?: AddUserImportedRecordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/AddImportUsersByCompanyId',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersUpdateUser(
requestBody?: UpdateUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/UpdateUser',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersUpdateNonPatientUser(
requestBody?: UpdateNonPatientUserRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/UpdateNonPatientUser',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersUpdateSiteUserSites(
requestBody?: UpdateSiteUserSitesRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/UpdateSiteUserSites',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersUpdateUserPermissions(
requestBody?: UpdateUserPermissionsRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/UpdateUserPermissions',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersUpdateParticipantImportedRecord(
requestBody?: UpdateUserImportedRecordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/UpdateParticipantImportedRecord',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param favouriteId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersRemoveFavourite(
favouriteId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/RemoveFavourite',
            query: {
                'favouriteId': favouriteId,
            },
        });
    }

    /**
     * @param authorCognitoSub 
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersDeletePatient(
authorCognitoSub?: string,
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/DeletePatient',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param authorCognitoSub 
     * @param email 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersDeletePatientByEmail(
authorCognitoSub?: string,
email?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/DeletePatientByEmail',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'email': email,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersClearNotificationSettings(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/ClearNotificationSettings',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersClearPreferredTimesPreference(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/ClearPreferredTimesPreference',
        });
    }

    /**
     * @param authorCognitoSub 
     * @param userCognitoSub 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersRemoveNonPatientUser(
authorCognitoSub?: string,
userCognitoSub?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/RemoveNonPatientUser',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'userCognitoSub': userCognitoSub,
            },
        });
    }

    /**
     * @param authorCognitoSub 
     * @param participantImportedId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersDeleteParticipantImportedRecord(
authorCognitoSub?: string,
participantImportedId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/DeleteParticipantImportedRecord',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'participantImportedId': participantImportedId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiUsersBulkDeleteParticipantImportedRecord(
requestBody?: BulkDeleteUserImportedRecordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Users/BulkDeleteParticipantImportedRecord',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersInviteParticipantToTrial(
requestBody?: InviteParticipantToTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/InviteParticipantToTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiUsersAutoRegisterPatientToTrial(
requestBody?: AddAutoRegisterTrialRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Users/AutoRegisterPatientToTrial',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersGetQualifyQuestions(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Users/GetQualifyQuestions',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersCreateUserAndQualifyUserAnswers(
requestBody?: CreateUserAndQualifyUserAnswersRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/CreateUserAndQualifyUserAnswers',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersVerifyPatientEmail(
requestBody?: VerifyPatientEmailRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/VerifyPatientEmail',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersResetUserPassword(
requestBody?: ResetUserPasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/ResetUserPassword',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersBotVerifyPatientEmail(
requestBody?: VerifyPatientEmailRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/BotVerifyPatientEmail',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiUsersBotResetUserPassword(
requestBody?: ResetUserPasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Users/BotResetUserPassword',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
