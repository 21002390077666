import React, {useEffect} from 'react';
import { useLocation, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";
import MainRoutes from './MainRoutes';
import useAuth from './../hooks/useAuth';
import Onboarding from "../shared/components/onboarding/onboarding";
import {ConfigProvider} from "react-avatar";
        
const EvrilinkRoutes = () => {


    const {user, init} = useAuth(); 
    const location = useLocation();

    const multiTabLogout = (e) => {
        if(e.key === 'refreshToken' && e.oldValue && !e.newValue) {
            init();
        }
    };

    
   
   /* Appcues tracking */

    useEffect(() => { 
        if (user && window.Appcues) {
            window.Appcues.identify(
                user.cognitoSub,
                {
                    firstName : user.firstName,
                    email : user.email,
                    role : user.role
                }
            );
        }
        
    },[user]);

    useEffect(() => {

        ReactGA.send('pageview');
        if (window.Appcues) {
            window.Appcues.page();
        }
    },[location]);

    /* Log out multitab */

    useEffect(() => {
        window.addEventListener('storage', multiTabLogout);
        return () => {
            window.removeEventListener('storage', multiTabLogout);
        }
    });

    return (
            
            
        <React.Fragment>
            <ConfigProvider colors={[
                '#fa9500',
                '#2a9d8f',
                '#3a86ff',
                '#ff006e',
                '#3a0ca3']}>
            <>
                <Onboarding />
                <MainRoutes />
            </>
            </ConfigProvider>


        </React.Fragment>


            
    );
};

export default EvrilinkRoutes;
