import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {FileLibraryAction} from "../interfaces/file-library.action";
import {FileLibraryState} from "../interfaces/file-library.state";
import {FileLibraryListModel} from "../models/file-library-list.model";
import moment from "moment";
import {FileModel} from "../models/file.model";
import {RequestModel} from "../models/request.model";
import {UnknownAction} from "redux";

const initialState = {
    trial: null,
    files: [],
    requests: [],
    isLoading: false,
    selectedRequest: null,
    isLoadingRequest: false,
    totalPages: 0,
    totalFiles: 0,
    filesPerPage: 20,
    savingImageId: null,
    savingRequestId: null
}


const fileLibraryReducer = (state: FileLibraryState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case actionTypes.FILE_LIBRARY_REQUEST_STATUS_SAVE:
            return produce(state, draft => {
                draft.savingRequestId = (action as any).savingRequestId;
            });
        case actionTypes.FILE_LIBRARY_REQUEST_STATUS_SAVED:
            return produce(state, draft => {
                draft.savingRequestId = null;
            });
        case actionTypes.FILE_LIBRARY_FILE_STATUS_SAVE:
            return produce(state, draft => {
                draft.savingImageId = (action as any).savingImageId;
            });
        case actionTypes.FILE_LIBRARY_FILE_STATUS_SAVED:
            return produce(state, draft => {
                draft.savingImageId = null;
            });
        case actionTypes.FILE_LIBRARY_REQUEST_LOAD:
            return produce(state, draft => {
                draft.isLoadingRequest = true;
            });
        case actionTypes.FILE_LIBRARY_REQUEST_LOADED:
            return produce(state, draft => {
                const request = (action as any).request;
                if (request) {
                    draft.selectedRequest = new RequestModel(
                        request.requestId,
                        request.description,
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientCognitoSub : '',
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientFirstName : '',
                        request.requestFileStatus.length ? request.requestFileStatus[0].patientLastName : '',
                        request.requestFileStatus,
                        request.trialImages ? request.trialImages
                            .map((file:any) => new FileModel(file.id,
                                file.imageKey,
                                file.description,
                                moment.utc(file.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                                request.requestId,
                                file.authorCognitoSub,
                                file.imageStatuses)
                            ) : []
                    )
                }
                draft.isLoadingRequest = false;
            });
        case actionTypes.FILE_LIBRARY_REQUEST_UNLOAD:
            return produce(state, draft => {
                draft.selectedRequest = null;
            });
        case actionTypes.FILE_LIBRARY_LOAD:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        case actionTypes.FILE_LIBRARY_LOADED:
            return produce(state, draft => {
                draft.trial = (action as any).trial;
                draft.totalFiles = (action as any).totalFiles;
                draft.totalPages = Math.ceil((action as any).totalFiles / draft.filesPerPage);

                const files: FileLibraryListModel[]  = [];
                const requests: RequestModel[] = [];

                (action as any).requests.forEach((r: any) => {
                    requests.push(new RequestModel(
                        r.requestId,
                        r.description,
                        '',
                        '',
                        '',
                        [],
                        []
                    ))
                });

                (action as any).files.forEach((f: any) => {
                            files.push(new FileLibraryListModel(
                                f.id,
                                f.imageKey,
                                f.description,
                                moment.utc(f.uploadDate).local().format('Do MMM, YYYY @ h:mma'),
                                f.imageStatuses,
                                f.patientFirstName,
                                f.patientLastName,
                                f.requestId,
                                f.patientCognitoSub
                            ))
                    });

                draft.requests = requests;
                draft.files = files;
                draft.isLoading = false;
            });
        default:
            return state;
    }
};

export default fileLibraryReducer;



