export const TRIAL_WITHDRAW = '@trial-details/TRIAL_WITHDRAW';
export const TRIAL_WITHDRAWN = '@trial-details/TRIAL_WITHDRAWN';
export const TRIAL_TAB_CHANGE = '@trial-details/TRIAL_TAB_CHANGE';
export const TRIAL_ATTACHMENTS_LOAD = '@trial-details/TRIAL_ATTACHMENTS_LOAD';
export const TRIAL_ATTACHMENTS_LOADED = '@trial-details/TRIAL_ATTACHMENTS_LOADED';
export const TRIAL_ATTACHMENTS_UPLOAD = '@trial-details/TRIAL_ATTACHMENTS_UPLOAD';
export const TRIAL_ATTACHMENTS_UPLOADED = '@trial-details/TRIAL_ATTACHMENTS_UPLOADED';
export const TRIAL_ATTACHMENTS_UPLOAD_FAILED = '@trial-details/TRIAL_ATTACHMENTS_UPLOAD_FAILED';
export const TRIAL_ATTACHMENTS_UPLOAD_DIALOG_CLOSED = '@trial-details/TRIAL_ATTACHMENTS_UPLOAD_DIALOG_CLOSED';
export const TRIAL_ATTACHMENTS_DELETE = '@trial-details/TRIAL_ATTACHMENTS_DELETE';
export const TRIAL_ATTACHMENTS_DELETED = '@trial-details/TRIAL_ATTACHMENTS_DELETED';

export const TRIAL_FILE_REQUESTS_LOAD = '@trial-details/TRIAL_FILE_REQUESTS_LOAD';
export const TRIAL_FILE_REQUESTS_LOADED = '@trial-details/TRIAL_FILE_REQUESTS_LOADED';
export const TRIAL_FILE_REQUEST_LOAD = '@trial-details/TRIAL_FILE_REQUEST_LOAD';
export const TRIAL_FILE_REQUEST_LOADED = '@trial-details/TRIAL_FILE_REQUEST_LOADED';
// export const TRIAL_FILE_REQUEST_UPDATE = '@trial-details/TRIAL_FILE_REQUEST_UPDATE';

export const TRIAL_DETAIL_LOAD = '@trial-details/TRIAL_DETAIL_LOAD';
export const TRIAL_DETAIL_LOADED = '@trial-details/TRIAL_DETAIL_LOADED';