/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddRemoveSitePatientRequest } from '../models/AddRemoveSitePatientRequest';
import type { UpdatePatientTrialSiteRequest } from '../models/UpdatePatientTrialSiteRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SitesService {

    /**
     * @param id 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiSitesGetSite(
id?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sites/GetSite',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param siteId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiSitesGetSiteTrialStatuses(
siteId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Sites/GetSiteTrialStatuses',
            query: {
                'siteId': siteId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiSitesReferPatientToSite(
requestBody?: AddRemoveSitePatientRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Sites/ReferPatientToSite',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiSitesUpdatePatientTrialSite(
requestBody?: UpdatePatientTrialSiteRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Sites/UpdatePatientTrialSite',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
