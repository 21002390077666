import * as React from "react";
import {UserPermissionTypesEnum} from "../../shared/permissions/enums/user-permission-types.enum";
import usePermissions from "../../hooks/use-permissions.hook";
import {UserPermissionsEnum} from "../../shared/permissions/enums/user-permissions.enum";
import Loadable from "../../ui-component/Loadable";
import {lazy} from "react";
import {useParams} from "react-router";
const FourOhFour = Loadable(lazy(() => import('../../views/404')));

const GuardedRoute = ({ component, resourceType, permissions, parentResourceType }: {component: any, resourceType: UserPermissionTypesEnum, permissions: UserPermissionsEnum[], parentResourceType?: UserPermissionTypesEnum}) => {

    let { tid, sid, uid } = useParams();

    const getResourceIdByType = (type: UserPermissionTypesEnum): number|undefined => {
        switch(type){
            case UserPermissionTypesEnum.Trial:
                return tid ? Number(tid) : undefined;
            case UserPermissionTypesEnum.Location:
                return sid ? Number(sid) : undefined;
            case UserPermissionTypesEnum.User:
                return uid ? Number(uid) : undefined;
            default:
                return undefined
        }
    }

    const resourceId: number|undefined = getResourceIdByType(resourceType);
    const parentResourceId: number|undefined = (parentResourceType) ? getResourceIdByType(parentResourceType) : undefined;
    const [allowed] = usePermissions(resourceType, permissions, resourceId, parentResourceType, parentResourceId);



    return allowed ? <React.Fragment>{component}</React.Fragment> : <React.Fragment><FourOhFour /></React.Fragment>;
}



export default GuardedRoute;