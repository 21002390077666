import {ActionTypeEnum} from "../shared/enums/actionTypeEnum";
import {AccessTime, Close, Done} from "@mui/icons-material";
import {overallStatuses, statuses} from "./constant";
import produce from "immer";
import * as actionTypes from "./actions";
import { TRIALS_NAV_LOAD, TRIALS_NAV_LOADED } from "./actions";
import {ONBOARDING_SLIDES} from "../shared/components/onboarding/onboardingSlides";
import {UserFavouriteTypeEnum} from "../shared/enums/userFavouriteTypeEnum";
import {TrialsService} from "../api/connect";
import {UnknownAction} from "redux";
import {AppReducerState} from "./interfaces/app-reducer.state";
import {AppDispatch} from "./store";

const initialState: AppReducerState = {
    statuses: statuses,
    actions: [
        { index: ActionTypeEnum.NONE, icon: <AccessTime color="secondary" />},
        { index: ActionTypeEnum.DISQUALIFY, icon: <Close color="error" />},
        { index: ActionTypeEnum.WITHDRAW, icon: <Close color="error" />},
        { index: ActionTypeEnum.REFER, icon: <Done color="success" />},
        { index: ActionTypeEnum.BOOK_PHONE_APPOINTMENT, icon: <Done color="success" />},
        { index: ActionTypeEnum.RECORD_NOTE, icon: <Done color="success" />},
        { index: ActionTypeEnum.RECORD_CONTACT_ATTEMPT, icon: <AccessTime color="secondary" />}
    ],
    overallStatus: overallStatuses,
    isLoadingTrials: true,
    totalTrials: 0,
    trials: [],
    onboardingKey: 'ONBOARDING_v8',
    disableOnboardingKey: 'DISABLE_ONBOARDING',
    showOnboarding: false,
    onboardingSlides: ONBOARDING_SLIDES,
    favourites: []
};

const appReducer = (state: AppReducerState = initialState, action: UnknownAction): AppReducerState => {
    switch (action.type) {
        case actionTypes.PREFERENCES_ADD_FAVOURITE:
            return produce(state, draft => {
                draft.favourites.push(action.favourite);
            });
        case actionTypes.PREFERENCES_REMOVE_FAVOURITE:
            return produce(state, draft => {
                draft.favourites = draft.favourites.filter(f => f.id !== (action as any).favourite.id);
            });
        case actionTypes.PREFERENCES_SAVE_FAVOURITES:
            return produce(state, draft => {
                draft.favourites = (action as any).favourites;
            });
        case actionTypes.SHOW_ONBOARDING:
            return produce(state, draft => {
                    draft.showOnboarding = true;
            });
        case actionTypes.CLOSE_ONBOARDING:
            return produce(state, draft => {
                draft.showOnboarding = false;
            });
        case actionTypes.CHECK_ONBOARDING:
            return produce(state, draft => {
                const seen = Boolean(localStorage.getItem(draft.onboardingKey));
                if (!seen && !Boolean(localStorage.getItem(draft.disableOnboardingKey))) {
                    draft.showOnboarding = true;
                }
            });
        case actionTypes.ONBOARDING_DONE:
            return produce(state, draft => {
                localStorage.setItem(draft.onboardingKey, String(true));
                draft.showOnboarding = false;
            });
        case actionTypes.TRIALS_NAV_TOGGLE_FAVOURITE:
            return produce(state, draft => {
                const trial = draft.trials.find(t => t.id === (action as any).trialId);
                trial.isFavourite = !trial.isFavourite;
            });
        case actionTypes.TRIALS_NAV_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrials = true;
            });
        case actionTypes.TRIALS_NAV_LOADED:
            return produce(state, draft => {

                const trials = (action as any).trials;

                const formattedTrials = trials.map((t: any) => {
                    return {
                        id: Number(t.id),
                        name: t.name,
                        protocolNumber: t.protocolNumber,
                        isFavourite: t.isFavourite,
                        trialIdentifier: t.trialIdentifier,
                        defaultSite: t.defaultSiteId,
                        defaultSiteName: t.defaultSiteName
                    }
                });

                draft.trials = (trials) ? formattedTrials.filter((f:any) => {
                    //([RoleEnum.SITE].includes(action.user.role)) ? f.sitesTotal > 0 : f
                    return f;
                }) : [];
                draft.totalTrials = draft.trials.length;
                draft.isLoadingTrials = false;
            });
        default:
            return state;
    }
};

export default appReducer;


// Effects
export function getNavTrials(user: any) {
    return async (dispatch: AppDispatch, getState: any) => {
        dispatch({type: TRIALS_NAV_LOAD});
        const favourites = getState().app.favourites;
        return TrialsService.getApiTrialsTrialPickerGetTrialNames()
            .then(trials => {
                dispatch({type: TRIALS_NAV_LOADED,
                    trials: trials.results,
                    favourites: favourites ? favourites.filter((f:any) => f.favouriteType === UserFavouriteTypeEnum.TRIAL) : [],
                    siteDefaults: favourites ? favourites.filter((f:any) => f.favouriteType === UserFavouriteTypeEnum.DEFAULT_SITE) : [],
                    allowedSites: user.sites,
                    user
                });
            })
            .catch(error => console.error(error));
    }
}

