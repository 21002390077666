import ReactGA from 'react-ga4';



export enum Analytics {
  Register = 'Register',
  RegisterFail = 'RegisterFail',
  SignIn = 'SignIn',
  SignInFail = 'SignInFail',
  EmailVerified = 'EmailVerified',
  VerificationRequest = 'VerificationRequest',
  ForgotPassword = 'ForgotPassword',
  TrialRegister = 'TrialRegister',
  TrialSurvey = 'TrialSurvey',
  TrialAppointment = 'TrialAppointment',
  Withdraw = 'Withdraw',
  DeleteAccount = 'DeleteAccount',
  SetTrial = 'SetTrial',
  PatientDrawerActivity = 'PatientDrawerActivity',
  PatientDrawerTrialDetails = 'PatientDrawerTrialDetails',
  PatientDrawerParticpantInfo = 'PatientDrawerParticpantInfo',
  PatientDrawerTrialSurvey = 'PatientDrawerTrialSurvey',
  PatientDrawerAppointments = 'PatientDrawerAppointments',
  PatientDrawerFileLibrary = 'PatientDrawerFileLibrary',
  PatientDrawerComments = 'PatientDrawerComments',
  CompanyAdd = 'CompanyAdd',
  CompanyDetails = 'CompanyDetails',
  CompanyAssociatedSites = 'CompanyAssociatedSites',
  CompanyAssociatedUsers = 'CompanyAssociatedUsers',
  CompanyAssociatedTrials = 'CompanyAssociatedTrials',
  UserAdd = 'UserAdd',
  UserDetails = 'UserDetails',
  UserAssociatedTrials = 'UserAssociatedTrials',
  TrialDetails = 'TrialDetails',
  TrialAssociatedUsers = 'TrialAssociatedUsers',
  TrialAssociatedSites = 'TrialAssociatedSites',

  ReportConversionRateTask1 = 'ReportConversionRateTask1',
  ReportConversionRateTask2 = 'ReportConversionRateTask2',
  ReportPerformanceDate = 'ReportPerformanceDate',
  ReportParticipantJourneyStage = 'ReportParticipantJourneyStage',
  ReportParticipantJourneySite = 'ReportParticipantJourneySite',
  ReportParticipantJourneyState = 'ReportParticipantJourneyState',
  ReportParticipantJourneyTable = 'ReportParticipantJourneyTable',
  ReportParticipantJourneyDate = 'ReportParticipantJourneyDate',
  ReportDisqualificationStage = 'ReportDisqualificationStage',
  ReportDisqualificationSite = 'ReportDisqualificationSite',
  ReportDisqualificationState = 'ReportDisqualificationState',
  ReportDisqualificationTable = 'ReportDisqualificationTable',
  ReportDisqualificationDate = 'ReportDisqualificationDate',
  ReportWithdrawalReasonsStage = 'ReportWithdrawalReasonsStage',
  ReportWithdrawalReasonsSite = 'ReportWithdrawalReasonsSite',
  ReportWithdrawalReasonsState = 'ReportWithdrawalReasonsState',
  ReportWithdrawalReasonsTable = 'ReportWithdrawalReasonsTable',
  ReportWithdrawalReasonsDate = 'ReportWithdrawalReasonsDate',
  ReportProgressCumulative = 'ReportProgressCumulative',
  ReportProgressState = 'ReportProgressState',
  ReportProgressSite = 'ReportProgressSite',
  ReportProgressFrequency = 'ReportProgressFrequency',
  ReportProgressStage1 = 'ReportProgressStage1',
  ReportProgressStage2 = 'ReportProgressStage2',
  ReportSiteDate = 'ReportSiteDate',
  ReportSiteTable = 'ReportSiteTable',
  ReportSiteTask = 'ReportSiteTask',
  ReportSiteStatus = 'ReportSiteStatus',
  ReportSiteSort = 'ReportSiteSort',
}

export const sendAnalytics = (options: {
  key: Analytics;
  role?: number;
  company?: string;
  trial?: string;
}) => {
  try {
    switch (options.key) {
      case Analytics.SignIn:
        let user_role = 'Participant';
        let user_company = 'Participant';
        switch (options.role) {
          case 2:
            user_role = 'Sponsor';
            user_company = 'Sponsor';
            break;
          case 3:
            user_role = 'Site';
            user_company = 'Site';
            break;
          case 4:
            user_role = 'Recruiter';
            user_company = 'Recruiter';
            break;
          case 5:
            user_role = 'Admin';
            user_company = 'Admin';
            break;
          case 6:
            user_role = 'Company';
            user_company = 'Company';
            break;
        }
        if (options.company) {
          user_company = options.company;
        }
        ReactGA.set({ user_properties: { user_role, user_company } });
        break;
      case Analytics.SetTrial:
      case Analytics.TrialRegister:
        let user_trial = options.trial;
        ReactGA.set({ user_properties: { user_trial } });
        if(options.key === Analytics.TrialRegister){
          (window as any).fbq('track', 'Start Trial');
        }
        break;
      default:
        break;
    }
    ReactGA.event({ category: 'User', action: options.key });
  } catch (e) {
    console.log(e);
  }
};

export default sendAnalytics;
