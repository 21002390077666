import {OnboardingTypeEnum} from "./onboardingTypeEnum";
import {UserRoleEnum} from "../../enums/user-role.enum";
import {UserPermissionTypesEnum} from "../../permissions/enums/user-permission-types.enum";
import {RoleEnum} from "../../enums/role.enum";

/*
 --- Permission types ---
 permissions: [
            { parentUserRole: RoleEnum.COMPANY ,userRole: UserRoleEnum.TRIAL_ADMIN, permissionType: UserPermissionTypesEnum.Trial },
            { parentUserRole: RoleEnum.COMPANY, userRole: UserRoleEnum.TRIAL_USER, permissionType: UserPermissionTypesEnum.Trial },
            { parentUserRole: RoleEnum.COMPANY, userRole: UserRoleEnum.TRIAL_SPONSOR, permissionType: UserPermissionTypesEnum.Trial },
            { parentUserRole: RoleEnum.COMPANY, userRole: UserRoleEnum.COMPANY_ADMIN, permissionType: UserPermissionTypesEnum.Company },
            { parentUserRole: RoleEnum.PARTICIPANT, userRole: null, permissionType: null },
            { parentUserRole: RoleEnum.ADMIN, userRole: null, permissionType: null }
        ]
 */

export const ONBOARDING_SLIDES = [
    // {
    //     id: 1,
    //     type: OnboardingTypeEnum.VIDEO,
    //     title: 'Streamlined File Submission for Participants',
    //     description: "Facilitate seamless collaboration between clinical trial recruitment staff and participants with our new file upload feature. Easily submit requested documents, records, and forms essential for the trial recruitment process. Simplify the communication and data-sharing process, ensuring a smooth and efficient experience for all participants involved in advancing medical research.",
    //     video: 'https://evrimaconnectonboardingmedia.s3.ap-southeast-2.amazonaws.com/File-upload.mp4',
    //     tag: 'New feature',
    //     permissions: [
    //         { parentUserRole: RoleEnum.PARTICIPANT, userRole: null, permissionType: null }
    //     ]
    // }
];