export const TRIALS_OVERVIEW_LOAD = '@trials/TRIALS_OVERVIEW_LOAD';
export const TRIALS_OVERVIEW_LOADED = '@trials/TRIALS_OVERVIEW_LOADED';
export const TRIALS_CHANGE_TRIAL_DEFAULT_LOCATION = '@trials/TRIALS_CHANGE_TRIAL_DEFAULT_LOCATION';
export const TRIALS_TRIAL_DEFAULT_LOCATION_CHANGED = '@trials/TRIALS_TRIAL_DEFAULT_LOCATION_CHANGED';
export const TRIALS_TOGGLE_FAVOURITE = '@trials/TRIALS_TOGGLE_FAVOURITE';
export const TRIALS_TOGGLE_FAVOURITE_CHANGED = '@trials/TRIALS_TOGGLE_FAVOURITE_CHANGED';
export const TRIALS_SAVE_DEFAULT_SITE = '@trials/SINGLE_TRIAL_SAVE_DEFAULT_SITE';
export const TRIALS_INVITES_LOAD = '@trials/TRIALS_INVITES_LOAD';
export const TRIALS_INVITES_LOADED = '@trials/TRIALS_INVITES_LOADED';
export const TRIALS_INVITES_ACCEPTING = '@trials/TRIALS_INVITES_ACCEPTING';
export const TRIALS_INVITES_ACCEPTED = '@trials/TRIALS_INVITES_ACCEPTED';
export const TRIALS_INVITES_DECLINING = '@trials/TRIALS_INVITES_DECLINING';
export const TRIALS_INVITES_DECLINED = '@trials/TRIALS_INVITES_DECLINED';