import {AppState} from "../../../../store/interfaces/app.state";
import {
    NOTIFICATION_PANEL_COMMENTS_LOAD,
    NOTIFICATION_PANEL_COMMENTS_LOADED, NOTIFICATION_PANEL_MARK_ALL_AS_READ,
    NOTIFICATION_PANEL_MARK_AS_READ, NOTIFICATION_PANEL_MARKED_ALL_AS_READ, NOTIFICATION_PANEL_MARKED_AS_READ
} from "./notification-panel.actions";
import {CommentsService} from "../../../../api/connect";

export function getComments(userCognitoSub: string, unreadOnly: boolean) {
    return async (dispatch: any, getState: AppState) => {
            dispatch({ type: NOTIFICATION_PANEL_COMMENTS_LOAD, unreadOnly});
            CommentsService.getApiCommentsGetCommentRead(userCognitoSub, unreadOnly)
                .then(resp => {
                    dispatch({type: NOTIFICATION_PANEL_COMMENTS_LOADED, comments: resp.results});
                }).catch(err => console.error(err));
    }
}

export function markAsRead(userCognitoSub: string, commentId: number) {
    return async (dispatch: any, getState: AppState) => {
        dispatch({ type: NOTIFICATION_PANEL_MARK_AS_READ, commentId});
        CommentsService.putApiCommentsUpdateReadComment(userCognitoSub, commentId)
            .then(resp => dispatch({ type: NOTIFICATION_PANEL_MARKED_AS_READ}))
            .catch(err => console.error(err));
    }
}

export function markAllAsRead(userCognitoSub: string, commentIds: number[]) {
    return async (dispatch: any, getState: AppState) => {
        dispatch({ type: NOTIFICATION_PANEL_MARK_ALL_AS_READ, commentIds});
        for await (const id of commentIds) {
            await CommentsService.putApiCommentsUpdateReadComment(userCognitoSub, id);
        }
        dispatch({ 
            type: NOTIFICATION_PANEL_MARKED_ALL_AS_READ,
            commentIds: commentIds
        });
    }
}