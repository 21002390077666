import {RecordStatuses, UserPermission} from "../../../../api/connect";
import {LocationModel} from "../../../../shared/components/location-selectors/models/location.model";
import {PermissionStatusEnum} from "../enums/permission-status.enum";

export class TrialAssociatedUserModel {
 constructor(public id: string | null | undefined,
             public name: string,
             public initials: string,
             public email: string | null | undefined,
             public company: string,
             public role: any,
             public status: RecordStatuses | undefined,
             public permissions: UserPermission[],
             public locations: LocationModel[],
             public associatedLocations: LocationModel[],
             public invitationStatus: PermissionStatusEnum,
             public isChangingRole: boolean,
             public isRemoving: boolean) {}
}