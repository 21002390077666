import styled from "@emotion/styled";
import {Switch} from "@mui/material";

export const ConnectSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    marginRight: '.5rem',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:  theme.palette.primary.main,
                opacity: 1,
               // border: `1px solid ${theme.palette.grey[500]}`,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
            '& .MuiSwitch-thumb': {
                color: '#fff',
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#fff',
            border: `6px solid ${theme.palette.primary.main}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity:  0.1,
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16.5,
        height: 16.5,
        color: '#fff'
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#c0c0c3',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    }
}));