export const NOTIFICATION_PREFERENCES_LOAD = '@notifications/NOTIFICATION_PREFERENCES_LOAD';
export const NOTIFICATION_PREFERENCES_LOADED = '@notifications/NOTIFICATION_PREFERENCES_LOADED';
export const NOTIFICATION_PREFERENCES_SAVE_GENERAL = '@notifications/NOTIFICATION_PREFERENCES_SAVE_GENERAL';
export const NOTIFICATION_PREFERENCES_SAVED_GENERAL = '@notifications/NOTIFICATION_PREFERENCES_SAVED_GENERAL';
export const NOTIFICATION_PREFERENCES_SAVE_ALL_TRIALS = '@notifications/NOTIFICATION_PREFERENCES_SAVE_ALL_TRIALS';
export const NOTIFICATION_PREFERENCES_SAVED_ALL_TRIALS = '@notifications/NOTIFICATION_PREFERENCES_SAVED_ALL_TRIALS';
export const NOTIFICATION_PREFERENCES_SAVE_SPECIFIC_TRIAL = '@notifications/NOTIFICATION_PREFERENCES_SAVE_SPECIFIC_TRIAL';
export const NOTIFICATION_PREFERENCES_SAVED_SPECIFIC_TRIAL = '@notifications/NOTIFICATION_PREFERENCES_SAVED_SPECIFIC_TRIAL';
export const NOTIFICATION_PREFERENCES_TOGGLE_GENERAL_NOTIFICATION_TYPE = '@notifications/NOTIFICATION_PREFERENCES_TOGGLE_GENERAL_NOTIFICATION_TYPE';
export const NOTIFICATION_PREFERENCES_TOGGLED_GENERAL_NOTIFICATION_TYPE = '@notifications/NOTIFICATION_PREFERENCES_TOGGLED_GENERAL_NOTIFICATION_TYPE';
export const NOTIFICATION_PREFERENCES_TOGGLE_TRIAL_NOTIFICATION_TYPE = '@notifications/NOTIFICATION_PREFERENCES_TOGGLE_TRIAL_NOTIFICATION_TYPE';
export const NOTIFICATION_PREFERENCES_TOGGLED_TRIAL_NOTIFICATION_TYPE = '@notifications/NOTIFICATION_PREFERENCES_TOGGLED_TRIAL_NOTIFICATION_TYPE';