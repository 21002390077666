import {createContext, useState} from "react";
import {AgreementsEnum} from "../shared/components/agreements/agreements.enum";

const AgreementsContext = createContext({
    isDialogOpen: false,
    isReadOnly: false,
    activeTab: String(AgreementsEnum.PrivacyPolicy as unknown as string),
    showTab: (tab: AgreementsEnum) => {},
    showDialog: (tab?: AgreementsEnum, readonly?: boolean) => {},
    hideDialog: () => {}
});

const AgreementsProvider = ({children}:any) => {

    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(String(AgreementsEnum.PrivacyPolicy as unknown as string));

    const showTab = (tab: AgreementsEnum) => {
        setActiveTab(String(tab as unknown as string));
    }

    const showDialog = (tab: AgreementsEnum|null = null, readonly = false): void => {
        if (tab !== null ){
            setActiveTab(String(tab as unknown as string));
        }
        setIsReadOnly(readonly);
        setDialogOpen(true);
    };

    const hideDialog = () => {
        setDialogOpen(false);
    };

    return (
        <AgreementsContext.Provider value={{ isDialogOpen, isReadOnly, activeTab, showTab, showDialog, hideDialog }}>
            {children}
        </AgreementsContext.Provider>
    );
};

export { AgreementsContext, AgreementsProvider };