import {FileModel} from "../../../file-library/models/file.model";


export class ParticipantFilesListModel extends FileModel {

    constructor(public id: number,
                public imageKey: string,
                public description: string,
                public uploadDate: string,
                public participantCognito: string,
                public statuses: any[]) {
        super(id,imageKey,description,uploadDate,-1,participantCognito,statuses);
    }
}
