import * as actionTypes from './company-management.actions';
import produce from 'immer';
import {CompanyManagementState} from '../interfaces/company-management.state';
import {CompanyManagementAction} from '../interfaces/company-management.action';
import {User, UserPermission, UserPermissionTypes} from "../../../../api/connect";
import {UserPermissionTypesEnum} from "../../../../shared/permissions/enums/user-permission-types.enum";
import {CompanyUserListModel} from "../models/company-user-list.model";
import {permissionsToRole} from "../../../../shared/permissions/functions/permission-template.function";
import {UserPermissionsEnum} from "../../../../shared/permissions/enums/user-permissions.enum";
import {makeInternalValue} from "../../../../shared/utils/make-internal-value.function";
import {UnknownAction} from "redux";

const initialState = {
  isLoadingCompanies: true,
  companies: [],
  totalCompanies: 0,
  company: null,
  isLoadingUsers: true,
  isSavingUser: false,
  isChangingRole: null,
  users: [],
  totalUsers: 0,
  isLoadingTrials: true,
  isSavingTrial: false,
  trials: [],
  totalTrials: 0,
  isLoadingLocations: true,
  isSavingLocation: false,
  locations: [],
  totalLocations: 0,  
};

const companyManagementReducer = (
  state: CompanyManagementState = initialState,
  action: UnknownAction
) => {
  switch ((action as any).type) {
    case actionTypes.COMPANY_MANAGEMENT_USER_REMOVE:
      return produce(state, draft => {
        const user = draft.users.find((u: CompanyUserListModel) => u.id === (action as any).removeUserId);
        if(user){
          user.isRemoving = true;
        }
      });
    case actionTypes.COMPANY_MANAGEMENT_USER_REMOVED:
      return produce(state, draft => {
        const user = draft.users.find((u: CompanyUserListModel) => u.id === (action as any).removeUserId);
        if(user){
          user.isRemoving = false;
        }
        draft.users = draft.users.filter((u: CompanyUserListModel) => u.id !== (action as any).removeUserId);
      });
    case actionTypes.COMPANY_MANAGEMENT_ROLE_CHANGE:
      return produce(state, (draft) => {
        const users = JSON.parse(JSON.stringify(draft.users)); // force state change
        draft.isChangingRole = (action as any).changingUserRole.id;
        const user = users.find((u: CompanyUserListModel) => u.id === (action as any).changingUserRole.id);
        if (user) {
          user.isChangingRole = true;
          user.locations = [];
        }
        draft.users = users;
      });
    case actionTypes.COMPANY_MANAGEMENT_ROLE_CHANGED:
      return produce(state, draft => {
        const user = draft.users.find((u: CompanyUserListModel) => u.id === (action as any).changingUserRole.id);
        if (user) {
          user.isChangingRole = false;
          user.role = (action as any).changingUserRole.newRole;
          user.permissions = (action as any).changingUserRole.updatedPermissions;
        }
        draft.isChangingRole = null;
      });
    case actionTypes.COMPANY_MANAGEMENT_LOCATIONS_SAVE:
      return produce(state, (draft) => {
        draft.isSavingLocation = true;
      });
    case actionTypes.COMPANY_MANAGEMENT_LOCATIONS_SAVED:
      return produce(state, (draft) => {
        draft.isSavingLocation = false;
      });
      case actionTypes.COMPANY_MANAGEMENT_LOAD:
        return produce(state, (draft) => {
          draft.isLoadingCompanies = true;
        });      
    case actionTypes.COMPANY_MANAGEMENT_LOADED:
      return produce(state, (draft) => {
        draft.companies = (action as any).companies ? (action as any).companies : [];
        draft.totalCompanies = (action as any).totalCompanies;
        draft.isLoadingCompanies = false;
      });
    case actionTypes.COMPANY_MANAGEMENT_UNSELECT_COMPANY:
      return produce(state, (draft) => {
        draft.company = null;
      });
    case actionTypes.COMPANY_MANAGEMENT_SELECTED_COMPANY:
      return produce(state, (draft) => {
        const company = (action as any).company;
        draft.company = {
          ...company,
         // internalValue: company.internalValue ? company.internalValue : makeInternalValue(company.state || '', company.addressLine2|| '', company.postCode || '', company.name || '')
          internalValue: company.internalValue ? company.internalValue : '*'
        };
      });
    case actionTypes.COMPANY_MANAGEMENT_USERS_LOAD:
      return produce(state, (draft) => {
        draft.isLoadingUsers = true;
      });
    case actionTypes.COMPANY_MANAGEMENT_USERS_LOADED:
      return produce(state, (draft) => {
        draft.isLoadingUsers = false;

        const userPermissions = (action as any).users;
        draft.users = userPermissions ? userPermissions.map((u: User) => {
              const permissions = u.permissions ? u.permissions?.filter((p: UserPermission) => p.type === UserPermissionTypesEnum.Company as unknown as UserPermissionTypes && p.resourceId === draft.company?.id) : [];
              return new CompanyUserListModel(
                  u.cognitoSub,
                  `${u.firstName} ${u.lastName}`,
                  `${u.firstName?.charAt(0)} ${u.lastName?.charAt(0)}`,
                  u.email,
                  permissionsToRole(permissions.flatMap((p: UserPermission) => p.permission) as unknown as UserPermissionsEnum[], UserPermissionTypesEnum.Company),
                  0,
                  u.recordStatus,
                  permissions,
                  false,
                  false
              );
            }) : [];
          draft.totalUsers = (action as any).totalUsers;
      });
    case actionTypes.COMPANY_MANAGEMENT_TRIALS_LOAD:
      return produce(state, (draft) => {
        draft.isLoadingTrials = true;
      });
    case actionTypes.COMPANY_MANAGEMENT_TRIALS_LOADED:
      return produce(state, (draft) => {
        draft.isLoadingTrials = false;
        draft.trials = (action as any).trials;
        draft.totalTrials = (action as any).totalTrials;
      });
      case actionTypes.COMPANY_MANAGEMENT_LOCATIONS_LOAD:
        return produce(state, (draft) => {
          draft.isLoadingLocations = true;
        });
      case actionTypes.COMPANY_MANAGEMENT_LOCATIONS_LOADED:
        return produce(state, (draft) => {
          draft.isLoadingLocations = false;
          draft.locations = (action as any).locations;
          draft.totalLocations = (action as any).totalLocations;
        });      
    default:
      return state;
  }
};

export default companyManagementReducer;
